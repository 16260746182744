.article-main
  .article-page
    width: 1340px
    margin: 50px auto 0 auto
    display: inline-block
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6784313725), rgb(255 255 255 / 90%))
    border-radius: 20px
    padding-top: 56px
    z-index: 2
    position: relative
    .bread
      ul
        list-style: none
        padding-left: 60px
        text-align: left
        li
          display: inline
          font-family: "cygre_bookbook", sans-serif
          font-size: 16px
          font-weight: 350
          line-height: 16px
          letter-spacing: 0em
          text-align: left
        li::after
          content: ""
          background-image: url("../../images/bread-arrow.png")
          background-repeat: no-repeat
          background-position: center
          width: 22px
          padding: 0 15px
          display: inline-block
          height: 8px
        li:last-of-type
          color: #D84313
        li:last-of-type::after
          display: none

    .articles-categories
      margin-top: 50px
      text-align: left
      padding-left: 60px
      .category
        width: 18%
        height: 44px
        border: 1px solid black
        border-radius: 10px
        margin-right: 14px
        display: inline-block
        cursor: pointer
        p
          width: fit-content
          margin: 14px auto
          vertical-align: middle
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 16px
          font-weight: 500
          a
            text-decoration: none
            color: black
      .category.active
        border: 1px solid #D84313
        color: #D84313
        p
          a
            text-decoration: none
            color: #D84313

      .categories-list-show
        min-height: 1100px
        width: 1220px
        transition: 0.5s
        margin-top: 35px
        display: flex
        position: relative
        .categories-list-item
          width: 365px
          margin-right: 63px
        .categories-list-item:last-of-type
          margin-right: 0
        .close-icon
          font-size: 30px
          position: absolute
          right: 0
          top: -10px
          cursor: pointer
        h4
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 22px
          font-weight: 500
          min-height: 45px
          max-height: 45px
        .categories-list-item-image
          width: 365px
          height: 345px
          background-color: #4E4E4E
          border-radius: 10px
          .frame
            width: 335px
            height: 320px
            overflow: hidden
            margin-left: 15px
            margin-top: 13px
            position: absolute
            border-radius: 10px
            img
              height: 320px
              margin-left: -25%
        .categories-list-item-text
          width: 365px
          font-family: "cygre_bookbook", sans-serif
          font-weight: 350
          font-size: 16px
          line-height: 17.6px
          margin-top: 36px
      .categories-list-hide
        max-height: 0
        overflow: hidden
    .article-page-main
      text-align: left
      h1
        font-family: 'cygre_mediummedium', sans-serif
        font-size: 32px
        font-weight: 500
        line-height: 32px
        letter-spacing: 1px
        text-align: left
        color: #D84313
        padding-left: 60px
        margin-top: 59px
        margin-bottom: 28px
      .article-page-top
        width: 1220px
        margin: 0 auto
        .article-page-top-image
          width: 750px
          height: 465px
          border: 15px solid #4E4E4E
          border-radius: 10px
          float: left
          margin-right: 20px
          margin-bottom: 20px
          overflow: hidden
          img
            width: 100%
        .more-articles
          width: 530px
          display: inline-block
          .more-articles-item:last-of-type
            border-bottom: none
          .more-articles-item
            height: 234px
            width: 405px
            margin: 0 auto
            vertical-align: middle
            border-bottom: 1px solid #CFCFCF
            position: relative
            h4
              font-family: 'cygre_mediummedium', sans-serif
              font-size: 16px
              font-weight: 700
              margin-bottom: 17px
              padding-top: 52px
              margin-top: 0
            .item-link
              font-family: 'cygre_mediummedium', sans-serif
              font-size: 13px
              font-weight: 500
              position: absolute
              bottom: 30px
              right: 0
              text-align: right
              a
                color: #D84313
            .more-articles-item-text
              font-family: "cygre_bookbook", sans-serif
              font-weight: 350
              font-size: 16px
              line-height: 16px
              letter-spacing: 0
              text-align: left
          .more-articles-text
            width: 405px
            margin: 0 auto
            font-family: 'cygre_mediummedium', sans-serif
            font-size: 16px
            font-weight: 500
            color: #D84313
            text-decoration: underline
        .article-main-text
          width: 1220px
          margin: 0 auto
          h2
            font-family: 'cygre_mediummedium', sans-serif
            font-size: 30px
            font-weight: 500
            line-height: 30px
            color: #D84313
          h3
            font-family: 'cygre_mediummedium', sans-serif
            font-size: 22px
            font-weight: 500
            line-height: 22px
            color: #D84313
          p
            margin-bottom: 1.5em
            font-family: "cygre_bookbook", sans-serif
            font-weight: 350
            font-size: 22px
            line-height: 22.56px
            text-align: justify
            min-height: 22px
          ul
            font-family: "cygre_bookbook", sans-serif
            font-weight: 350
            font-size: 22px
            line-height: 22.56px
            display: grid
          ol
            font-family: "cygre_bookbook", sans-serif
            font-weight: 350
            font-size: 22px
            line-height: 22.56px
            display: grid
            li
              padding: 5px 0
          img
            width: 714px
            height: auto
            border: 11px solid #4E4E4E
            border-radius: 25px
            float: left
            margin-right: 20px
            margin-bottom: 20px
          div
            text-align: center
        .buttons
          display: flex
          .button
            width: 410px
            height: 58px
            background-image: url("../../images/send-button.png")
            color: white
            font-family: 'cygre_mediummedium', sans-serif
            font-size: 18px
            line-height: 18.45px
            border: none
            border-radius: 10px
            cursor: pointer
            text-align: center
            vertical-align: middle
            margin-right: 50px
            text-decoration: none
            .button-text
              margin-top: 20px

      .reading-more
        width: 1220px
        margin: 0 auto
        h3
          font-family: 'cygre_lightlight', sans-serif
          color: #D84313
          font-size: 32px
          font-weight: 300
          line-height: 33px
          letter-spacing: 0em
          text-align: left

    .article-page-main-hide
      display: none

@media(max-width: 1439px)
  .article-main
    .article-page
      width: 100%
      .articles-categories
        display: flex
        width: 100%
        justify-content: center
        padding-left: unset
      .article-page-main
        .article-page-top
          width: 95%
          .article-page-top-image
            width: 60%
            height: auto

          .article-main-text
            width: auto
        .reading-more
          width: 95%
          margin: 0 auto
        .read-more-block
          width: 95%


@media(max-width: 1024px)
  .article-main
    .article-page
      .bread
        width: 95%
        ul
          padding-left: 20px
          li
            display: block
            margin-bottom: 10px
      .articles-categories
        flex-direction: column
        gap: 10px
        .category
          width: 90%
          align-self: center
          margin-right: unset
      .article-page-main
        h1
          margin: 30px auto
          padding-left: unset
          text-align: center
        .article-page-top
          .article-page-top-image
            width: auto
            margin-right: unset
            float: unset
            img
              height: unset
              width: 100%
          .article-main-text
            h2
              text-align: center
            p
              min-height: unset
              line-height: unset
          .buttons
            gap: 10px
            .button
              width: 50%
              margin-right: unset
          .article-main-text
            img
              width: 93%
        .read-more-block
          display: flex
          flex-direction: column
          a
            width: 100%
            .category-view-articles-item
              padding-right: unset
              .category-view-articles-item-image
                .frame
                  img
                    width: auto
                    margin-left: unset
.main-directions
  max-width: 1340px
  margin: 0 auto
  padding-top: 25px
  position: relative
  .directions-blocks
    padding: 0 32px 60px 32px
    border-radius: 20px
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF80), linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))

    .directions-block
      width: 300px
      height: 230px
      display: inline-block
      padding-right: 25px
      margin-top: 52px
      position: relative
      .directions-block-header
        background-color: #3D4C3E
        width: 300px
        height: 35px
        color: white
        border-radius: 5px
        cursor: pointer
        position: absolute
        top: 0
        h4
          width: 80%
          float: left
          margin-bottom: 0
          margin-top: 8px
          font-family: "cygre_bookbook", sans-serif
          font-weight: 400
          text-align: left
          padding-left: 15px
          transition: 0.5s
          a
            text-decoration: none
            color: white
        svg
          margin-top: 13px
      .directions-block-header:hover
        h4
          font-weight: 700
          transition: 0.5s
      .directions-block-image
        margin-top: 13px
        width: 300px
        position: absolute
        top: 40px
        img
          transition: 0.5s
      .directions-block-image:hover
        img
          transform: scale(1.05)
          transition: 0.5s
    .directions-block:nth-of-type(4)
      padding-right: 0
      .directions-block-header
        background: url("../../images/maindirectheaders1.png")
    .directions-block:nth-of-type(8)
      padding-right: 0
      .directions-block-header
        background: url("../../images/maindirectheaders2.png")
    .directions-block:nth-of-type(2)
      .directions-block-header
        background: url("../../images/maindirectheaders4.png")
    .directions-block:nth-of-type(1)
      .directions-block-header
        background: url("../../images/maindirectheaders1.png")
    .directions-block:nth-of-type(3)
      .directions-block-header
        background: url("../../images/maindirectheaders1.png")
    .directions-block:nth-of-type(5)
      .directions-block-header
        background: url("../../images/maindirectheaders1.png")
    .directions-block:nth-of-type(6)
      .directions-block-header
        background: url("../../images/maindirectheaders1.png")
    .directions-block:nth-of-type(7)
      .directions-block-header
        background: url("../../images/maindirectheaders3.png")
  .about-centre
    max-width: 1340px
    margin: 45px auto 0 auto
    padding: 0 47px 0px 47px
    border-radius: 20px
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF80), linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.3))
    h2
      text-align: left
      width: 100%
      font-family: 'cygre_lightlight', sans-serif
      font-weight: 400
      font-size: 32px
      color: #D84313
      padding-top: 44px
      margin-bottom: 25px
    .about-centre-block
      .about-text
        display: inline-block
        text-align: left
        font-family: "cygre_bookbook", sans-serif
        font-weight: bold
        font-size: 20px
        p
          padding-left: 0
          margin-top: 0
          width: 70%
          font-family: "cygre_bookbook", sans-serif
          font-size: 20px
          font-weight: 350
          line-height: 24px
          letter-spacing: 0.02em
          text-align: left

      .about-link
        position: relative
        bottom: 40px
        right: 47px
        font-family: "cygre_bookbook", sans-serif
        font-weight: 300
        font-size: 32px
        text-align: right
        a
          text-decoration: none
          color: #D84313
          svg
            padding-left: 20px
            vertical-align: middle

@media(max-width: 1339px)
  .main-directions
    .directions-blocks
      display: flex
      flex-wrap: wrap
      align-content: flex-start
      justify-content: center
      gap: 20px
      padding-top: 50px
      .directions-block
        padding-right: unset

@media(max-width: 1024px)
  .main-directions
    .directions-blocks
      padding: 0 20px 30px 20px
    .about-centre
      padding: 0 20px
      .about-centre-block
        .about-text
          p
            width: 100%
            text-align: justify
            font-size: 18px
        .about-link
          display: block
          width: 100%
          text-align: center
          position: unset
          padding-bottom: 20px
          a
            width: 85%
            display: block
.mobile-menu
  .menu-close-button
    display: block
    position: absolute
    top: 50%
    right: 4vw
    font-size: 40px
  .menu-mobile-collapsed
    display: none
  .menu-mobile-expanded
    display: block
    background-color: white
    overflow: scroll
    max-height: 85vh
    .mobile-menu-block
      font-family: "cygre_lightlight", sans-serif
      font-weight: 700
      ul
        margin: 0
        list-style: none
        padding: 0
        li
          a
            text-decoration: none
            color: black
          padding: 15px 0
          font-size: 18px
          border-bottom: 1px solid grey
          svg
            top: 3px
            left: 3px
            position: relative
          .sub-menu-hide
            max-height: 0
            overflow: hidden
            ul
              li
                background-color: rgba(217, 217, 217, 0.53)
                font-size: 14px
                padding: 7px 0
          .sub-menu-vis
            height: auto
            transition: max-height 0.5s
            max-height: 500px
            overflow: scroll
            padding-top: 10px
            margin-bottom: -15px
            border-bottom: 2px solid black
            ul
              li
                background-color: rgba(217, 217, 217, 0.53)
                a
                  text-decoration: none
                  color: black
            .sub-menu-services
              display: flex
              height: 500px
              margin-top: 5px
              .services-column
                padding-top: 15px
                overflow: auto
                border-right: 1px solid #999892
                ul
                  width: 100%
                  li
                    font-size: 13px
                    padding: 7px 2px
                    border-bottom: 2px solid #00b300
                    a
                      pointer-events: none
              .specs-column
                background-color: #e3c986
                padding-top: 15px
                height: unset
                overflow: auto
                border-right: 1px solid #999892
                ul
                  width: 100%
                  li
                    font-size: 13px
                    padding: 7px 2px
                    border-bottom: 2px solid #8b762b
                  .mobile-link
                    display: block
                    margin-bottom: 5px
                    a
                      color: #D84313
              .diseases-column
                padding-top: 15px
                height: unset
                overflow: auto
                background-color: #f1e48c
                ul
                  width: 100%
                  li
                    font-size: 13px
                    padding: 7px 2px
                    border-bottom: 2px solid #8b762b


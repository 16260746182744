.sub-menu-services
  text-transform: none
  div
    padding-top: 30px
    ul
      list-style: none
      padding: 10px 25px
      width: 80%
      margin-left: 20px
      li
        padding: 10px 0
        cursor: pointer
        a
          text-decoration: none
          color: black
      li.active
        a
          color: #D84313
          text-decoration: underline
      li:hover
        a
          color: #D84313
  .services-column
    display: block
    float: left
    width: 33%
    background-color: #ffedd9
  .specs-column
    display: block
    float: left
    width: 33%
    border-right: 1px solid #ebebeb
    height: 100%
    ul
      .mobile-link
        display: none

  .diseases-column
    display: block
    float: left
    width: 33%
@font-face
  font-family: 'cygrebold'
  src: url('./fonts/cygre-bold-bf63eeebf566546-webfont.woff2') format('woff2'), url('./fonts/cygre-bold-bf63eeebf566546-webfont.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'cygreregular'
  src: url('./fonts/cygre-regular-bf63eeebf4df0b6-webfont.woff2') format('woff2'), url('./fonts/cygre-regular-bf63eeebf4df0b6-webfont.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'ostrovskybold'
  src: url('./fonts/ostrovsky-bold-webfont.woff2') format('woff2'), url('./fonts/ostrovsky-bold-webfont.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'cygre_bookbook'
  src: url('./fonts/cygre-book-webfont.woff2') format('woff2'), url('./fonts/cygre-book-webfont.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
    font-family: 'cygre_lightlight'
    src: url('./fonts/cygre-light-webfont.woff2') format('woff2'), url('./fonts/cygre-light-webfont.woff') format('woff')
    font-weight: normal
    font-style: normal

@font-face
    font-family: 'cygre_mediummedium'
    src: url('./fonts/cygre-medium-webfont.woff2') format('woff2'), url('./fonts/cygre-medium-webfont.woff') format('woff')
    font-weight: normal
    font-style: normal

@font-face
    font-family: 'cygre_semiboldsemibold'
    src: url('./fonts/cygre-semibold-webfont.woff2') format('woff2'), url('./fonts/cygre-semibold-webfont.woff') format('woff')
    font-weight: normal
    font-style: normal

@font-face
    font-family: 'cygre_thinthin'
    src: url('./fonts/cygre-thin-webfont.woff2') format('woff2'), url('./fonts/cygre-thin-webfont.woff') format('woff')
    font-weight: normal
    font-style: normal

.container
  max-width: 1440px
  padding: 0 10px
  margin-right: auto
  margin-left: auto

.App
  text-align: center
  color: #3a3a3a
  .App-header
    display: none
  .container-fluid
    .video-back
      position: fixed
      top: 0
      left: 0
      width: 100vw
      z-index: 0
      .video
        width: 100%
        height: 100%
    .video-back::after
      background-image: url("./images/overlay-pattern2.png")
      background-repeat: repeat
      content: ""
      display: block
      width: 100vw
      height: 100vh
      position: fixed
      top: 0
      background-color: rgb(255 255 255 / 35%)


@media(max-width: 1439px)
  .App
    .container-fluid
      .video-back
        width: 100vw
        overflow-x: hidden
        .video
          height: 100vh
          width: auto
  .container
    width: 100%


@media(max-width: 1024px)
  .App
    .container-fluid
      .video-back
        .video
          overflow: hidden
          width: auto
          height: 100vh

@media(max-width: 1024px) and (orientation: landscape)
  .App
    .container-fluid
      .video-back
        .video
          overflow: hidden
          width: 100vw
          height: auto
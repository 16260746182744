.vacancies-main
  .vacancies-page
    width: 1340px
    margin: 50px auto 0 auto
    display: inline-block
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6784313725), rgb(255 255 255 / 90%))
    border-radius: 20px
    padding-top: 56px
    z-index: 2
    position: relative
    .bread
      ul
        list-style: none
        padding-left: 60px
        text-align: left
        li
          display: inline
          font-family: "cygre_bookbook", sans-serif
          font-size: 16px
          font-weight: 350
          line-height: 16px
          letter-spacing: 0em
          text-align: left
        li::after
          content: ""
          background-image: url("../../images/bread-arrow.png")
          background-repeat: no-repeat
          background-position: center
          width: 22px
          padding: 0 15px
          display: inline-block
          height: 8px
        li:last-of-type
          color: #D84313
        li:last-of-type::after
          display: none
    h1
      text-align: left
      font-family: 'cygre_mediummedium', sans-serif
      font-size: 32px
      font-weight: 500
      line-height: 32px
      letter-spacing: 1px
      color: #D84313
      padding-left: 60px
      margin-top: 59px
      margin-bottom: 28px
    .vacancies-text
      width: 1220px
      margin: 0 auto
      h2
        font-family: 'cygre_mediummedium', sans-serif
        font-size: 30px
        font-weight: 500
        line-height: 30px
        color: #D84313
      h3
        font-family: 'cygre_mediummedium', sans-serif
        font-size: 22px
        font-weight: 500
        line-height: 22px
        color: #D84313
      p
        margin-bottom: 1.5em
        font-family: "cygre_bookbook", sans-serif
        font-weight: 350
        font-size: 22px
        line-height: 22.56px
        text-align: justify
        min-height: 22px
      ul
        font-family: "cygre_bookbook", sans-serif
        font-weight: 350
        font-size: 22px
        line-height: 22.56px
        display: grid
      ol
        font-family: "cygre_bookbook", sans-serif
        font-weight: 350
        font-size: 22px
        line-height: 22.56px
        display: grid
        li
          padding: 5px 0
      img
        width: 714px
        height: auto
        border: 11px solid #4E4E4E
        border-radius: 25px
        float: left
        margin-right: 20px
        margin-bottom: 20px
      div
        text-align: center

@media(max-width: 1024px)
  .vacancies-main
    .vacancies-page
      width: 100%
      min-height: 400px
      h1
        text-align: center
        padding-left: unset
      .vacancies-text
        width: 95%
        margin: 0 auto
.directions
  animation: slideIn 2s
  @keyframes slideIn
    0%
      opacity: 0
    100%
      opacity: 1
  .column-6
    width: 16.66%
    float: left
    min-height: 500px
    box-shadow: 0 0 10px rgba(0,0,0,0.5)
    transition: 0.8s
    cursor: pointer
    ul
      text-align: left
      list-style: circle
    .childrens
      width: 80%
      float: left
      min-height: 500px
      background-color: #79e7c2
      transition: 0.8s
    .childrens:hover
      background-color: burlywood
      transition: 0.8s
    .logoped
      width: 20%
      float: left
      min-height: 500px
      position: relative
      background-color: #ff9a75c7
      transition: 0.8s
      h2
        position: absolute
        margin: 0
        top: 40%
        writing-mode: vertical-lr
        text-orientation: mixed
        transform: rotateZ(180deg)
    .logoped:hover
      background-color: darkred
      transition: 0.8s
      color: white
  .column-6:hover
    background-color: #61dafb
    transition: 0.8s
.intro
  width: 100vw
  height: 100vh
  z-index: 100
  position: fixed
  overflow: hidden
  .focus-back
    position: absolute
    top: 0
    left: 0
    width: 99.1vw
    height: 100vh
    background-color: rgb(0 0 0 / 80%)
    transition: 0.5s
    z-index: 101
  .video
    z-index: 102
    position: relative
    box-shadow: 0 0 117px 100px rgb(0 0 0)
    width: 100vw
    margin-top: -4%
.hided
  display: none
  visibility: hidden


@media(max-width: 1339px)
  .intro
    .video
      width: 100vw
      margin-top: 10%

@media(max-width: 1024px)
  .intro
    .focus-back
      width: 100%
      background-color: rgb(0 0 0 / 90%)
    .video
      width: 100%
      height: auto
      margin-top: 0
      margin-left: unset
.main-page-news
  max-width: 1340px
  margin: 45px auto 0 auto
  .news-block
    height: 978px
    position: relative
    .news-header
      position: absolute
      width: 388px
      height: 72px
      border: 2px solid transparent
      margin: 0 auto
      background: url("../../images/headhrame2.png") no-repeat
      top: 65px
      left: 35.5%
      h2
        font-family: "cygre_lightlight", sans-serif
        font-weight: 300
        font-size: 32px
        color: #D84313
    .new-big-block
      height: 100%
      width: 33.3%
      display: inline-flex
      background: linear-gradient(0deg, #FFFFFF, #FFFFFF80), linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.3))
      border-radius: 30px
      .news-item
        margin: 51.3% auto 0 auto
        width: 400px
        height: 563px
        border: 1px solid #D9D9D9
        border-radius: 10px
        .new-header
          text-align: left
          min-height: 60px
          h3
            padding-left: 20px
            font-family: 'cygre_mediummedium', sans-serif
            font-weight: 500
            font-size: 16px
            padding-top: 22px
            svg
              padding-right: 12px
        .news-short
          text-align: left
          border-top: 1px solid #CFCFCF
          border-bottom: 1px solid #CFCFCF
          min-height: 208px
          p
            padding-top: 23px
            padding-bottom: 43px
            font-family: 'cygre_lightlight', sans-serif
            font-weight: 350
            font-size: 16px
            width: 90%
            margin: 0 auto
            line-height: 17.52px
        .news-date-see-more
          .news-date
            width: 45%
            display: inline-block
            text-align: left
            font-family: 'cygre_mediummedium', sans-serif
            font-weight: 500
            font-size: 16px
            padding-left: 20px
            padding-top: 30px
          .see-more
            width: 45%
            display: inline-block
            text-align: right
            font-family: 'cygre_mediummedium', sans-serif
            font-weight: 500
            font-size: 18px
            padding-right: 20px
            padding-top: 30px
        .opacity-layer
          position: absolute
          width: 400px
          height: 202px
          background-color: #ffffff36
    .more-news
      width: 260px
      position: absolute
      bottom: 72px
      right: 47px
      .arrow
        width: 10%
        display: inline-block
        position: relative
        top: -4px
        padding-left: 12px
      .more-news-text
        width: 85%
        display: inline-block
        font-family: 'cygre_lightlight', sans-serif
        font-weight: 300
        font-size: 33px
        color: #D84313
        a
          text-decoration: none
          color: #D84313

@media(max-width: 1439px)
  .main-page-news
    .news-block
      display: flex
      max-height: 100vh
      .more-news
        bottom: 4vh
      .news-header
        width: 30%
        height: auto
        background-size: contain
        left: 35%
        top: 4vh
        h2
          margin-top: 2vh
      .new-big-block
        width: 33.33%
        .news-item
          width: 100%
          margin-top: 15vh
          .news-image
            .opacity-layer
              display: none
            img
              width: 100%
          .new-header
            min-height: unset
            h3
              padding-top: unset
          .news-short
            p
              padding-bottom: 1vh
              padding-top: 1vh
          .news-date-see-more
            display: flex


@media(max-width: 1024px)
  .main-page-news
    .news-block
      flex-direction: column
      max-height: unset
      height: auto
      .news-header
        width: 98%
        left: 0
        top: 0
        position: relative
        background: linear-gradient(0deg, #FFFFFF, rgba(255, 255, 255, 0.5019607843)), linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.3))
        border-radius: 30px
      .new-big-block
        width: 100%
        height: auto
        .news-item
          margin-top: 0
          border: none
          height: auto
          .news-short
            min-height: unset
          .news-date-see-more
            .news-date
              padding-top: 10px
              padding-bottom: 10px
            .see-more
              padding-top: 10px
              padding-bottom: 10px
      .more-news
        display: block
        position: relative
        width: 100%
        right: 0
        background: linear-gradient(0deg, #FFFFFF, rgba(255, 255, 255, 0.5019607843)), linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.3))
        bottom: unset
        padding: 15px 0
        border-radius: 30px
.admin-services
  .left-column
    width: 25%
    float: left
    min-height: 20vw
    background-color: #dbffb7
    display: inline-block
    padding-left: 10px
    .left-column-service
      .left-column-service-name
        width: 75%
        display: inline-block
        text-align: left
        cursor: pointer
      .left-column-service-buttons
        width: 25%
        display: inline-block
        .edit-service-form
          display: inline-block
  .right-column
    width: 74%
    display: inline-block
    min-height: 20vw
    background-color: #dbffb7
    border-left: 1px solid black
    .column-1
      width: 50%
      float: left
      .right-column-spec-name
        width: 74%
        display: inline-block
        text-align: left
        padding-left: 10px
      .right-column-spec-buttons
        width: 23%
        display: inline-block
        cursor: pointer
        .edit-spec-article-form
          display: inline-block
    .column-2
      width: 50%
      float: left
      .right-column-disease-name
        width: 74%
        display: inline-block
        text-align: left
        padding-left: 10px
      .right-column-disease-buttons
        width: 23%
        display: inline-block
        cursor: pointer
        .edit-disease-form
          display: inline-block
.first-menu
  position: relative
  width: 1340px
  margin: 0 auto
  .logo-main
    width: 120px
    position: absolute
    top: 40px
    left: 12px
    z-index: 3

    img
      position: relative
      box-shadow: none

  .menu
    height: 35px
    width: 80px
    display: block
    position: absolute
    top: 66px
    right: 96px
    border-radius: 10px
    background: url("../../images/button-menu2.png")
    cursor: pointer
    z-index: 3

    .collapsed
      display: none

    .menu-label
      font-family: 'cygrebold', sans-serif
      font-weight: 700
      width: 80px
      height: 35px
      padding-top: 10px
      position: relative
      color: white
      font-size: 14px
    .expanded
      width: 300px
      position: absolute
      top: 40px
      left: 0px
      background-color: #eeefde
      border-radius: 20px
      box-shadow: 2px 3px 7px #6e5858
      z-index: 100
      nav
        padding: 20px 0
        a
          display: block
          text-align: center
          padding: 10px 20px
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 18px
          color: black
          text-decoration: none
    .focus-back
      position: fixed
      top: 0
      left: 0
      width: 100vw
      height: 100vh
      background-color: rgb(0 0 0 / 39%)
      z-index: 99
      transition: 0.5s
      .focus-back-inviz
        visibility: hidden
        transition: 0.5s

  .header-lk
    width: 150px
    height: 35px
    position: absolute
    top: 66px
    right: 409px
    z-index: 5
    cursor: pointer
    background: url("../../images/secmenurecord.png")

    span
      padding-top: 10px
      position: absolute
      width: 148px
      left: 0
      font-family: 'cygrebold', sans-serif
      font-weight: 700
      font-size: 16px
      color: white

  .header-rec
    width: 150px
    height: 35px
    border-radius: 10px
    border: 1px solid #4E4E4E
    color: #D84313
    position: absolute
    top: 66px
    right: 249px
    z-index: 5
    cursor: pointer

    span
      padding-top: 10px
      position: absolute
      width: 148px
      left: 0
      font-family: 'cygrebold', sans-serif
      font-weight: 700
      font-size: 16px

  .header-eye
    width: 35px
    height: 35px
    z-index: 5
    position: absolute
    top: 66px
    cursor: pointer
    right: 51px
    background: url("../../images/bad-vis2.png")

    svg
      padding-top: 13px

  .header-sound
    width: 158px
    height: 35px
    background-color: #FFFFFFCC
    border-radius: 10px
    z-index: 5
    position: absolute
    top: 811px
    cursor: pointer
    right: 18vw

    img
      left: 0
    span
      left: 40px
      color: #4E4E4E
      font-family: 'cygrebold', sans-serif
      font-weight: 700
      font-size: 14px
      padding-top: 10px
      position: absolute


  .info
    display: block

    .label-name
      width: 30%
      display: inline-block

    .label-info
      width: 70%
      display: inline-block

    nav
      height: 50px
      width: 90%

      a
        padding: 14px
        display: inline-block

  div.player-1
    position: absolute
    right: 462px
    padding: 0
    top: 810px
    z-index: 6
    background-color: unset
    box-shadow: unset
    img
      position: relative

    .rhap_main
      .rhap_controls-section
        margin-top: 0


@media(max-width: 1339px)
  .first-menu
    width: 100%
.main-page-blog
  max-width: 1340px
  margin: 45px auto 0 auto
  position: relative
  .blog-block
    display: flex
    .left-side
      width: 54.73%
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1))
      border-radius: 20px
      min-height: 647px
      display: inline-block
      .player
        margin-left: 35px
        border: 13px solid #4E4E4E
        border-radius: 10px

      h2
        text-align: left
        padding-left: 35px
        margin-top: 51px
        font-family: 'cygre_lightlight', sans-serif
        font-weight: 300
        font-size: 32px
        color: #D84313
      .video-link
        margin-top: 57px
        text-align: right
        padding-right: 30px
        a
          font-family: 'cygre_lightlight', sans-serif
          font-weight: 300
          font-size: 33px
          color: #D84313
          text-decoration: none
          margin-bottom: -15px
          display: inline-block
        .arrow
          padding-left: 12px
          display: inline-block
          position: relative
          top: -4px
    .right-side
      width: 45.27%
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1))
      border-radius: 20px
      min-height: 647px
      display: inline-block
      h2
        text-align: left
        padding-left: 35px
        margin-top: 51px
        font-family: 'cygre_lightlight', sans-serif
        font-weight: 300
        font-size: 32px
        color: #D84313

@media(max-width: 1439px)
  .main-page-blog
    .blog-block
      .left-side
        h2
          margin-top: 30px
        .player
          margin-left: 1.5vw
          width: 90% !important
      .right-side
        h2
          margin-top: 30px
        .blog-block-articles
          .article-block
            width: 100%
            .article-image
              height: 10vh
              width: 10vh
              margin-left: 1.5vw
              img
                width: 100%
                height: 100%
            .article-text
              padding-left: 0

@media(max-width: 1024px)
  .main-page-blog
    .blog-block
      flex-direction: column
      .left-side
        width: 100%
        .player
          margin: 0 auto
        .video-link
          padding-right: unset
          text-align: center
      .right-side
        width: 100%
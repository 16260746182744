.online-record
  div.record-modal-collapsed
    display: none
  div.record-modal-expanded
    max-width: 500px
    min-width: 400px
    position: absolute
    top: 0
    left: 0
    display: block
  div.focus-back
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background-color: rgb(0 0 0 / 39%)
    z-index: 99
    transition: 0.5s
    .focus-back-inviz
      visibility: hidden
      transition: 0.5s


.modal-record
  position: absolute
  inset: 3vw
  min-width: 30%
  background: linear-gradient(0deg, #dfffd5, rgb(193 213 192 / 70%))
  border-radius: 15px
  .your-number
    width: 35%
    margin: 50px auto 0 auto
    text-align: left
    font-family: 'cygre_lightlight', sans-serif
    font-weight: 300
    font-size: 28px
    color: #D84313
  form
    display: flex
    width: 35%
    margin: 20px auto 0 auto
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 30px
    .input-label
      height: 50px
      width: 100%
      position: relative
      input
        height: 100%
        width: 97%
        border-radius: 10px
        border-width: 1px
        font-family: 'cygre_mediummedium', sans-serif
        font-weight: 500
        color: black
        font-size: 18px
        padding-left: 3%
      span
        position: absolute
        right: 1vw
        bottom: 0
    label
      span
        .agree-label
          font-family: 'cygre_bookbook', sans-serif
          font-weight: 350
          font-size: 10px
    .send-problem
      width: 410px
      height: 58px
      border-radius: 10px
      background-image: url("../../images/send-button.png")
      color: white
      font-family: 'cygre_mediummedium', sans-serif
      font-weight: 500
      font-size: 22px
      border: none
      cursor: pointer
    .send-problem:disabled
      background-image: url("../../images/send-button.png")
      opacity: 0.6
      cursor: not-allowed
  .closeButton
    position: absolute
    top: 1vw
    right: 1vw
    font-size: 30px
    background: none
    border: none
    cursor: pointer


@media(max-width: 1024px)
  .modal-record
    top: 0
    left: 0
    right: 0
    width: 100%
    .your-number
      width: 90%
      margin: 50px auto 0 auto
      font-size: 20px
      text-align: justify
    form
      width: 90%
      .send-problem
        width: 100%
.which-specialist
  max-width: 1340px
  height: 55px
  margin: 55px auto 0 auto
  position: relative
  background: linear-gradient(180deg, #FFFFFF, rgba(255, 255, 255, 0.6))
  border-radius: 10px
  border: 1px solid black
  a
    text-decoration: none
    h3
      font-family: 'cygre_semiboldsemibold', sans-serif
      font-size: 20px
      font-weight: 600
      line-height: 21px
      letter-spacing: 0em
      text-align: center
      color: #D84313
  svg
    position: absolute
    top: 23px
    right: 27px


@media(max-width: 1024px)
  .which-specialist
    width: 99%
    height: auto
    margin-top: 25px
    a
      width: 85%
      display: block
      h3
        font-size: 4vw
    svg
      top: 35px
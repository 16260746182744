.doctor-main
  background-image: linear-gradient(179.97deg, rgba(182, 203, 200, 1), rgba(227, 227, 187, 1), rgba(117, 96, 98, 1), rgba(246, 229, 212, 1), rgba(251, 236, 221, 1), rgba(219, 173, 145, 1))
  .doctor-page
    width: 1340px
    margin: 90px auto 0 auto
    display: inline-block
    background: linear-gradient(0deg, #ffffffad, #ffffff7d)
    border-radius: 20px
    padding-top: 56px
    position: relative
    z-index: 2
    .bread
      margin-top: -20px
      margin-bottom: 50px
      ul
        list-style: none
        padding-left: 60px
        text-align: left
        li
          display: inline
          font-family: "cygre_bookbook", sans-serif
          font-size: 16px
          font-weight: 350
          line-height: 16px
          letter-spacing: 0em
          text-align: left
        li::after
          content: ""
          background-image: url("../../images/bread-arrow.png")
          background-repeat: no-repeat
          background-position: center
          width: 22px
          padding: 0 15px
          display: inline-block
          height: 8px
        li:last-of-type
          color: #D84313
        li:last-of-type::after
          display: none
    .left-column
      width: 30%
      float: left
      .doc-img
        width: 360px
        height: 345px
        background-color: white
        margin-left: 57px
        border-radius: 10px
        img
          padding-top: 15px
          width: auto
          max-height: 219px
        .button
          width: 330px
          height: 58px
          margin: 15px auto 0 auto
          background: url("../../images/send-button.png")
          border-radius: 10px
          overflow: hidden
          a
            padding-top: 23px
            display: block
            font-family: "cygre_bookbook", sans-serif
            font-weight: 350
            font-size: 16px
            color: white
      .documents-header
        margin-top: 34px
        margin-bottom: 17px
        font-family: 'cygre_mediummedium', sans-serif
        font-weight: 500
        font-size: 13px
        color: #4E4E4E
        text-align: left
        margin-left: 57px
      .documents
        width: 360px
        margin-left: 57px
        text-align: left
        transition: max-height 500ms ease
        overflow: hidden
        max-height: 164px
        .documents-item
          display: inline-block
          padding-right: 30px
          margin-bottom: 10px
          img
            border-radius: 10px
        .documents-item:nth-of-type(3n)
          padding-right: 0
        .documents-item:hover div
          background: url("../../images/doc-back.png") no-repeat
          border-radius: 10px
      .open
        max-height: 330px
        transition: max-height 300ms ease
      .more-documents
        margin-left: 57px
        font-family: 'cygre_mediummedium', sans-serif
        font-weight: 500
        font-size: 13px
        color: #D84313
        width: 360px
        cursor: pointer

    .right-column
      width: 70%
      float: left
      padding-bottom: 50px
      .titul
        h2
          font-family: 'cygre_mediummedium', sans-serif
          color: #4E4E4E
          font-weight: 500
          font-size: 32px
          text-transform: uppercase
          width: 75%
          display: inline-block
          margin-bottom: 24px
          margin-top: 5px
        .stag
          width: 25%
          display: inline-block
          font-family: "cygre_bookbook", sans-serif
          font-weight: 350
          font-size: 16px
          color: black
          text-align: left
        .fullspeciality
          text-align: left
          padding-left: 75px
          .spec-item
            width: auto
            display: inline-block
            padding: 10px 20px
            border: 1px solid black
            border-radius: 10px
            margin-right: 15px
      .about-yourself
        width: 805px
        text-align: left
        margin-left: 75px
        background-color: white
        border-radius: 30px
        margin-top: 35px
        h3
          padding-top: 46px
          padding-left: 37px
          font-family: 'cygre_mediummedium', sans-serif
          color: #D84313
          font-weight: 500
          margin-bottom: 17px
        p
          padding-left: 37px
          padding-right: 17px
          padding-bottom: 76px
          font-family: "cygre_bookbook", sans-serif
          font-weight: 350
          font-size: 16px
      .how-i-work
        width: 805px
        text-align: left
        margin-left: 75px
        background-color: white
        border-radius: 30px
        margin-top: -19px
        h3
          padding-top: 46px
          padding-left: 37px
          font-family: 'cygre_mediummedium', sans-serif
          color: #D84313
          font-weight: 500
          margin-bottom: 17px
        p
          padding-left: 37px
          padding-right: 17px
          font-family: "cygre_bookbook", sans-serif
          font-weight: 350
          font-size: 16px
        p:last-of-type
          padding-bottom: 60px
      .timelines
        width: 805px
        text-align: left
        margin-left: 75px
        background-color: white
        border-radius: 30px
        margin-top: -19px
        h3
          padding-top: 46px
          padding-left: 37px
          font-family: 'cygre_mediummedium', sans-serif
          color: #D84313
          font-weight: 500
          margin-bottom: 17px
      .metodi
        width: 805px
        min-height: 180px
        text-align: left
        margin-left: 75px
        background-color: white
        border-radius: 30px
        margin-top: -19px
        h3
          padding-top: 46px
          padding-left: 37px
          font-family: 'cygre_mediummedium', sans-serif
          color: #D84313
          font-weight: 500
          margin-bottom: 17px
        .metodi-block
          width: 717px
          margin: 0 auto 0 37px
          .metod
            width: 30%
            display: inline-block
            font-family: "cygre_bookbook", sans-serif
            font-weight: 350
            font-size: 16px
            padding-right: 3.3%
    .link-to-home
      position: absolute
      bottom: 70px
      width: 215px
      height: 33px
      left: 52px
      .arrow
        width: 36px
        float: left
      svg
        padding-right: 12px
        margin-top: -5px
      a
        font-family: 'cygre_lightlight', sans-serif
        font-size: 32px
        text-decoration: none
        font-weight: 300
        color: #D84313


@media(max-width: 1024px)
  .doctor-main
    .doctor-page
      width: 100%
      .bread
        width: 95%
        ul
          padding-left: 20px
          li
            display: block
            margin-bottom: 10px
        ul
          li:last-of-type
            font-size: 24px
            margin-top: 30px
            width: 100%
            display: block
            line-height: 24px
      .left-column
        width: 100%
        float: unset
        .doc-img
          margin: 0 auto
        .documents-header
          width: 80%
          margin: 30px auto
          text-align: center
        .documents
          margin: 0 auto
        .more-documents
          margin: 0 auto
      .right-column
        width: 100%
        float: unset
        .titul
          h2
            width: 100%
            margin-top: 25px
          .stag
            width: 100%
            text-align: center
            margin-bottom: 30px
          .fullspeciality
            padding-left: unset
            width: 90%
            margin: 0 auto
            .spec-item
              margin-right: 10px
              margin-bottom: 10px
              padding: 5px 10px
        .about-yourself
          width: 100%
          margin-left: unset
        .how-i-work
          width: 100%
          margin-left: unset
        .timelines
          width: 100%
          margin-left: unset
          .timeline
            .timeline-eventline
              width: 80%
            .events-bar
              width: 80%
              padding-left: 0
              margin: 0 auto
              li:last-of-type
                .year
                  left: -12px
            .year-detail
              width: 100%
              padding-bottom: 40px
              .year-text
                p
                  width: auto
                  padding-left: 10px
        .metodi
          width: 100%
          margin-left: unset
          min-height: 320px
          h3
            padding-left: unset
            text-align: center
            font-size: 28px
          .metodi-block
            width: auto
            margin-left: unset
            .metod
              width: 100%
              display: block
              text-align: center
              padding: 15px 0
              border-bottom: 1px solid #e1e1e1
      .link-to-home
        left: 23%
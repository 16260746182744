.main-page
  /*background-image: linear-gradient(179.97deg, rgba(182, 203, 200, 1), rgba(227, 227, 187, 1), rgba(117, 96, 98, 1), rgba(246, 229, 212, 1), rgba(251, 236, 221, 1), rgba(219, 173, 145, 1))*/
  width: 100%
  animation: slideIn 0.5s
  z-index: 2
  position: relative
  @keyframes slideIn
    0%
      opacity: 0
    100%
      opacity: 1
  .no-column
    width: 100%
    min-height: 38vw
    background-color: rgb(255 158 0 / 88%)
    position: relative
    .bubbles
      width: 100%
      position: absolute
      display: block
      object-fit: fill
      top: 0
      left: 0
      z-index: -100
    h1
      margin-top: 0
  .empty-box
    min-height: 800px
    max-width: 1440px
    margin: 0 auto
  .container-fluid
    width: 100%
    overflow: hidden
    /*background: linear-gradient(179.97deg, rgb(199 255 140 / 49%) 1.89%, rgb(210 188 142 / 68%) 21.29%, rgb(195 156 79 / 17%) 32.21%, rgb(181 147 78 / 66%) 54.05%, rgba(246, 242, 234, 0.74) 83.21%, rgb(101 123 29 / 40%) 97.49%)*/

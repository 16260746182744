.footer
  height: 70px
  position: relative
  .copyright
    text-align: center
    margin: 144px auto 0 auto
    width: 170px
    font-family: "cygre_bookbook", sans-serif
    font-size: 13px
    font-weight: 350
    background: #ffffffb8
    box-shadow: 0 0 13px 13px #ffffffb8

@media(max-width: 1439px)
  .footer
    height: 20vh
    display: flex
    align-items: center
    justify-content: center
    .copyright
      margin: unset

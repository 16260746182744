.blog-block-articles
  margin-top: 33px
  .article-block
    width: 560px
    height: 134px
    border-top: 1px solid #CFCFCF
    padding: 40px 0
    .article-image
      display: inline-block
      float: left
      height: 110px
      border-radius: 50%
      overflow: hidden
    .article-text
      width: 73%
      display: inline-block
      padding-left: 34px
      h4
        width: 100%
        font-family: 'cygre_mediummedium', sans-serif
        font-weight: 500
        font-size: 16px
        padding-left: 0
        margin: 0
        text-align: left
      p
        font-family: 'cygre_bookbook', sans-serif
        text-align: left
        -webkit-line-clamp: 4
        display: -webkit-box
        -webkit-box-orient: vertical
        overflow: hidden
      .article-link
        text-align: left
        font-family: 'cygre_mediummedium', sans-serif
        font-weight: 500
        font-size: 13px
        text-decoration: underline
        cursor: pointer
        width: fit-content
  .articles-link
    margin-top: 12px
    text-align: right
    padding-right: 40px
    a
      font-family: 'cygre_lightlight', sans-serif
      font-weight: 300
      font-size: 33px
      color: #D84313
      text-decoration: none
      margin-bottom: -15px
      display: inline-block
    .arrow
      padding-left: 12px
      display: inline-block
      position: relative
      top: -4px
.articles
  width: 95%
  margin: 0 auto
  .article-item
    width: 100%
    div
      display: inline-block
      height: 55px
      border: 1px solid black
      overflow: hidden
    .article-header
      width: 19%
    .article-datetime
      width: 10%
    .article-description
      width: 39%
    .article-category
      width: 7%
    .article-author
      width: 13%
    .article-actions
      width: 10%
      .button-del
        display: block

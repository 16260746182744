.licenses-all
  .licenses-page
    width: 1340px
    margin: 50px auto 0 auto
    display: inline-block
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6784313725), rgb(255 255 255 / 90%))
    border-radius: 20px
    padding-top: 56px
    z-index: 2
    position: relative
    padding-bottom: 50px
    h1
      font-family: 'cygre_mediummedium', sans-serif
      font-size: 32px
      font-weight: 500
      line-height: 32px
      letter-spacing: 1px
      color: #D84313
      margin-top: 50px
      margin-bottom: 100px
    .docs-list
      list-style: none
      width: 50%
      margin: 0 auto
      padding-left: unset
      .docs-list-item
        padding: 20px
        background-color: #1a9aef
        background-image: url("../../images/send-button.png")
        color: white
        font-family: 'cygre_mediummedium', sans-serif
        font-size: 18px
        line-height: 18.45px
        border: none
        border-radius: 15px
        cursor: pointer
        text-align: center
        vertical-align: middle
        background-size: cover
        margin-bottom: 20px

.Modal
  border: 1px solid rgb(204, 204, 204)
  background: rgb(255, 255, 255)
  overflow: auto
  border-radius: 4px
  outline: none
  padding: 20px
  z-index: 501
  width: 40%
  height: auto
  display: block
  margin: 50px auto
  .docs-list-modal-close-button
    cursor: pointer
  .react-pdf__Page__canvas
    margin: 0 auto
  .pdf-nav
    text-align: center
    font-family: 'cygre_mediummedium', sans-serif
    button
      font-family: 'cygre_mediummedium', sans-serif
    .link-open
      padding: 10px
.Overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: #000000c9


@media(max-width: 1024px)
  .licenses-all
    .licenses-page
      width: 100%
      .docs-list
        width: 80%
  .Modal
    width: 100%
    padding: unset
    .react-pdf__Page__canvas
      width: 100% !important
.feedback-main
  .feedback-page
    width: 1340px
    margin: 50px auto 0 auto
    display: inline-block
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6784313725), rgb(255 255 255 / 90%))
    border-radius: 20px
    padding-top: 56px
    z-index: 2
    position: relative
    padding-bottom: 50px
    h1
      font-family: 'cygre_mediummedium', sans-serif
      font-size: 32px
      font-weight: 500
      line-height: 32px
      letter-spacing: 1px
      color: #D84313
      margin-top: 30px
      margin-bottom: 30px
    .feedback-reviews-list
      margin: 0 50px
      display: flex
      gap: 3.3%
      justify-content: center
      flex-wrap: wrap
      .feedback-review
        width: 30%
        background-color: #ffffffb8
        border-radius: 20px
        cursor: pointer
        margin-bottom: 20px
        transition: 0.2s
        h2
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 24px
          font-weight: 500
          line-height: 24px
          letter-spacing: 1px
          color: #9d2600
        .review-rating
          justify-content: center
        .review-text
          display: -webkit-box
          -webkit-box-orient: vertical
          margin: 10px 20px
          text-align: justify
          overflow: hidden
          -webkit-line-clamp: 3
          font-family: "cygre_bookbook", sans-serif
          font-size: 16px
          font-weight: 350
          line-height: 16px
      .feedback-review-active
        width: 50%
        position: relative
        background-color: #ffffffb8
        border-radius: 20px
        margin-bottom: 20px
        transition: 0.2s
        .review-rating
          justify-content: center
        .review-text
          font-family: "cygre_bookbook", sans-serif
          font-size: 16px
          font-weight: 350
          line-height: 16px
          text-align: justify
          margin: 10px 20px
        h2
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 24px
          font-weight: 500
          line-height: 24px
          letter-spacing: 1px
          color: #9d2600
    .score
      font-family: "cygre_bookbook", sans-serif
      font-size: 20px
      font-weight: 350
      line-height: 16px
      margin-bottom: 30px
      span
        font-family: 'cygre_mediummedium', sans-serif
        font-size: 24px
        font-weight: 500
    .feedback-form
      display: flex
      justify-content: center
      margin-bottom: 30px
      form
        width: 40%
        background-color: #ffffffba
        padding: 20px
        border-radius: 20px
        .input-label
          width: 100%
          display: block
          padding: 10px
          text-align: left
          input
            width: 95%
            font-family: 'cygre_mediummedium', sans-serif
            font-size: 24px
            font-weight: 500
            line-height: 24px
            letter-spacing: 1px
          textarea
            width: 95%
            font-family: 'cygre_mediummedium', sans-serif
            font-size: 18px
            min-height: 150px
        .input-captcha
          padding: 10px
          display: block
        .submit-button
          width: 200px
          height: 40px
          background-image: url("../../images/send-button.png")
          color: white
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 18px
          line-height: 18.45px
          border: none
          border-radius: 10px
          cursor: pointer
          text-align: center
          vertical-align: middle
          margin-top: 20px
        .submit-button:disabled
          opacity: 0.6
          cursor: not-allowed
      .input-checkbox
        display: flex
        justify-content: center
        label
          width: 80%
          span
            .agree-label
              width: auto


@media(max-width: 1024px)
  .feedback-main
    .feedback-page
      width: 100%
      .feedback-form
        form
          width: auto
          .input-checkbox
            label
              width: 95%
      .feedback-reviews-list
        margin: unset
        flex-direction: column
        .feedback-review
          width: 95%
          align-self: center
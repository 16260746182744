.main-page-vr
  max-width: 1340px
  margin: 45px auto 0 auto
  .vr-block
    height: 485px
    background-color: rgba(255, 255, 255, 0.6)
    border-radius: 30px
    padding-top: 65px
    .vr-header
      width: 388px
      height: 72px
      border: 2px solid transparent
      margin: 0 auto
      background: url("../../images/headhrame2.png") no-repeat
      h2
        font-family: 'cygre_lightlight', sans-serif
        font-weight: 300
        font-size: 32px
        color: #D84313
        margin: 20px 0
    .vr-in-develop
      margin-top: 58px
      font-family: 'cygre_mediummedium', sans-serif
      font-weight: 500
      font-size: 16px
    .vr-clock
      margin-top: 58px

@media(max-width: 1024px)
  .main-page-vr
    .vr-block
      .vr-header
        width: auto
        background-size: contain
        max-width: 410px
        h2
          font-size: 28px
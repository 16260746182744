.header-sound
  width: 190px
  height: 48px
  background-color: #FFFFFFCC
  border-radius: 10px
  z-index: 5
  position: absolute
  top: -150px
  cursor: pointer
  right: 103px

  img
    position: relative
    left: 0
  span
    left: 40px
    color: #4E4E4E
    font-family: 'cygrebold', sans-serif
    font-weight: 700
    font-size: 14px
    padding-top: 10px
  div.header-sound-icon
    width: 38px
    height: 30px
    position: absolute
    left: 0
    top: 8px
    border-right: 2px solid #848484
    .sound-of
      padding-top: 3px
      padding-left: 5px
    .sound-on
      padding-top: 7px
      padding-left: 5px
  div.choice-sound
    position: relative
    width: 152px
    left: 20px
    top: 2px
    .choice-block
      position: relative
      width: 100%
      text-align: left
      padding-left: 9px
      input[type="radio" i]
        margin-left: 0
        margin-right: 10px
        accent-color: #D84313
      label
        font-family: 'cygrebold', sans-serif
        font-weight: 700
        font-size: 14px
        top: -3px
        position: relative
        svg
          display: none

div.player-1
  position: absolute
  right: 120px
  padding: 0
  top: 15px
  z-index: 6
  background-color: unset
  box-shadow: unset
  img
    position: relative

  .rhap_main
    .rhap_controls-section
      margin-top: 0

.header-sound-fix
  width: 190px
  height: 48px
  background-color: rgb(255 255 255 / 95%)
  border-radius: 10px
  z-index: 5
  position: absolute
  top: 10px
  cursor: pointer
  right: 138px
  img
    position: relative
    left: 0
  span
    left: 40px
    color: #4E4E4E
    font-family: 'cygrebold', sans-serif
    font-weight: 700
    font-size: 14px
    padding-top: 10px
  div.header-sound-icon
    width: 38px
    height: 30px
    position: absolute
    left: 0
    top: 8px
    border-right: 2px solid #848484
    .sound-of
      padding-top: 3px
      padding-left: 5px
    .sound-on
      padding-top: 7px
      padding-left: 5px
  div.choice-sound
    position: relative
    width: 152px
    left: 20px
    top: 2px
    .choice-block
      position: relative
      width: 100%
      text-align: left
      padding-left: 9px
      input[type="radio" i]
        margin-left: 0
        margin-right: 10px
        accent-color: #D84313
      label
        font-family: 'cygrebold', sans-serif
        font-weight: 700
        font-size: 14px
        top: -3px
        position: relative
        svg
          display: none

.player-3
  position: absolute
  right: 160px

  div.player-1
    position: absolute
    right: 0
    padding: 0
    top: 15px
    z-index: 6
    background-color: unset
    box-shadow: unset
    img
      position: relative

    .rhap_main
      .rhap_controls-section
        margin-top: 0
        .rhap_play-pause-button
          font-size: unset

@media(max-width: 1024px)
  .header-sound
    div.choice-sound
      .choice-block
        label
          font-size: 15px
          top: 0px
          span
            display: none
          svg
            display: inline-block

@media(max-width: 500px)
  .second-header
    height: auto

.second-header
  height: 91px
  position: sticky
  top: 0
  background: linear-gradient(180deg, #FFFFFF, rgb(255 255 255 / 60%))

  z-index: 6

  .container
    position: relative
    margin: 0 auto
    height: 100%

    div
      display: inline-block
      position: absolute

  .menu-second
    height: 55px
    .menu-close-button
      display: none
    .menu-container
      width: 1340px
      border-radius: 10px
      margin: -27px auto 0 auto
      height: 55px
      text-align: left
      font-family: 'cygre_mediummedium', sans-serif
      font-size: 18px
      text-transform: uppercase
      position: relative
      background: url("../../images/secmenuback.png")

    a
      cursor: pointer
      .menu-second-element
        display: inline-block
        color: white
        margin-top: 18px
        margin-left: 53px
        position: relative
        img
          padding-left: 10px
          position: absolute
          top: 0
        .sub-menu
          display: block
          position: absolute
          left: -50px
          top: 20px
          width: 250px
          background-color: white
          color: black
          opacity: 0
          transform: scaleY(0)
          transform-origin: 0 0
          transition: .5s ease-in-out
          ul
            list-style: none
            padding: 10px 25px
            li
              padding: 10px 0
      .menu-second-element:hover
        color: #D84313
        text-decoration: underline
        .sub-menu
          top: 40px
          opacity: 1
          transform: scaleY(1)
          border-radius: 10px
          overflow: hidden
          ul
            list-style: none
            padding: 10px 25px
            li
              padding: 10px 0

    a:first-of-type
      .menu-second-element
        margin-left: 55px
    a:last-of-type
      .menu-second-element:last-of-type
        margin-left: 85px
    .menu-second-element-blog
      display: inline-block
      color: white
      margin-top: 18px
      margin-left: 53px
      position: relative
      height: 77%
      a
        text-decoration: none
        color: white
      img
        padding-left: 10px
        position: absolute
        top: 0
      .sub-menu
        display: block
        position: absolute
        left: -50px
        top: 20px
        width: 250px
        background-color: #D84313
        color: white
        opacity: 0
        transform: scaleY(0)
        transform-origin: 0 0
        transition: .5s ease-in-out
        ul
          list-style: none
          padding: 10px 25px
          li
            padding: 10px 0
    .menu-second-element-blog:hover
      a
        color: #D84313
        text-decoration: underline
      .sub-menu
        top: 40px
        opacity: 1
        transform: scaleY(1)
        border-radius: 10px
        overflow: hidden
        ul
          list-style: none
          padding: 10px 25px
          li
            padding: 10px 0
            a
              color: white
              text-decoration: none
            a:hover
              color: black
              text-decoration: none

    .menu-second-element-services
      display: inline-block
      color: white
      margin-top: 18px
      margin-left: 53px
      position: relative
      cursor: pointer
      height: 77%
      img
        padding-left: 10px
        position: absolute
        top: 0
      .sub-menu-services
        display: block
        position: absolute
        left: -5.5vw
        top: 40px
        width: 55vw
        background-color: white
        color: black
        opacity: 0
        transform: scaleY(0)
        transform-origin: 0 0
        transition: .5s ease-in-out
    .menu-second-element-services:hover
      .sub-menu-services
        top: 40px
        opacity: 1
        transform: scaleY(1)
        border-radius: 10px
        overflow: hidden

  .second-header-menu
    height: 35px
    width: 70px
    display: block
    top: 34px
    right: 372px
    border-radius: 10px
    background: url("../../images/button-menu.png")
    cursor: pointer
    z-index: 1

    .collapsed
      display: none

    .menu-label
      font-family: 'cygrebold', sans-serif
      font-weight: 700
      width: 70px
      height: 35px
      padding-top: 10px
      position: relative
      color: white
      font-size: 14px


    .expanded
      width: 300px
      top: 40px
      left: 0
      background-color: #eeefde
      border-radius: 20px
      box-shadow: 2px 3px 7px #6e5858
      z-index: 100
      nav
        padding: 20px 0
        a
          display: block
          text-align: center
          padding: 10px 20px
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 18px
          color: black
          text-decoration: none
    .focus-back
      position: fixed
      top: 0
      left: 0
      width: 100vw
      height: 100vh
      background-color: rgb(0 0 0 / 39%)
      z-index: 99
      transition: 0.5s
    .focus-back-inviz
      visibility: hidden
      transition: 0.5s

  .online-record
    top: 16px
    right: 190px
    width: 150px
    height: 35px
    border-radius: 10px
    border: 1px solid #D84313
    animation: blink 2s infinite
    @keyframes blink
      0%
        box-shadow: 0 0 20px rgb(216,67,19,0)
      50%
        box-shadow: 0 0 20px rgba(216,67,19,1)
      100%
        box-shadow: 0 0 20px rgba(216,67,19,0)
    .online-record-label
      font-size: 16px
      font-family: 'cygrebold', sans-serif
      width: 100%
      position: relative
      padding-top: 10px
      color: #D84313
      line-height: 16px
    svg
      display: none
  .second-header-lk
    top: 16px
    right: 359px
    width: 150px
    height: 35px
    background: url("../../images/secmenurecord.png")
    .lk-label
      font-size: 16px
      padding-top: 10px
      position: absolute
      width: 148px
      left: 0
      font-family: 'cygrebold', sans-serif
      font-weight: 700
      color: white
      line-height: 16px
    svg
      display: none

  .second-header-eye
    width: 35px
    height: 35px
    z-index: 5
    position: absolute
    top: 16px
    cursor: pointer
    right: 60px
    background: url("../../images/bad-vis2.png")
    svg
      padding-top: 13px
  .second-header-questions
    width: 35px
    height: 35px
    z-index: 5
    position: absolute
    top: 16px
    cursor: pointer
    right: 103px
    background: url("../../images/bad-vis2.png")
    svg
      color: white
      padding-top: 5px
  .second-header-phone
    font-family: 'cygrebold', sans-serif
    font-size: 21px
    font-weight: 700
    color: #4E4E4E
    left: 590px
    top: 23px
    a
      text-decoration: none
      color: #4E4E4E
      svg
        display: none

  .second-header-logo
    left: 62px
    top: -4px

  .second-header-search
    left: 239px
    width: 250px
    top: 16px

    svg
      position: absolute
      left: 14px
      top: 10px
      color: black
    .close-icon
      position: absolute
      left: 90%
      top: 10px
      color: black
      font-size: 20px
    input
      height: 34px
      width: 215px
      border-radius: 10px
      background-color: rgb(255 255 255 / 60%)
      border: 1px solid black
      caret-color: black
      padding-left: 35px
      color: black
      font-family: 'cygre_bookbook', sans-serif



@media(max-width: 1439px) and (orientation: landscape)
  .second-header
    height: 100px
    .container
      display: flex
      justify-content: space-around
      .second-header-lk
        position: relative
        right: unset
        top: 16px
      .second-header-logo
        position: unset
      .online-record
        position: relative
        right: unset
      .second-header-phone
        position: unset
        margin-top: 25px
      .player-3
        position: relative
        right: unset
        width: 35px
        .rhap_main
          position: unset
          .rhap_controls-section
            position: unset
            .rhap_main-controls
              display: block
              position: unset
              button
                margin: 0
      .second-header-questions
        position: relative
        right: unset
      .second-header-eye
        position: relative
        right: unset
      .second-header-search
        position: unset
        margin-top: 16px
        left: unset
        top: unset
        display: flex
        justify-content: center
        flex-wrap: wrap
        svg
          position: unset
          margin-top: 10px
          margin-right: -25px
          z-index: 1
        .close-icon
          margin-left: -25px

        input
          width: auto
        .close-icon
          left: 80%
        svg
          left: 13%
    .menu-second
      a:first-of-type
        .menu-second-element
          margin-left: unset
      a
        .menu-second-element
          margin-left: unset
      a:last-of-type
        .menu-second-element:last-of-type
          margin-left: unset
      .menu-container
        display: flex
        width: 100%
        background-size: cover
        justify-content: space-around
        font-size: 1.7vw
        .menu-second-element-blog
          margin-left: unset
          .sub-menu
            left: -1vw
        .menu-second-element-services
          margin-left: unset
          .sub-menu-services
            font-size: 1.4vw
            margin-left: -13vw
            width: 80vw
        .menu-second-element-blog:last-of-type
          margin-right: 2vw
          .sub-menu
            left: -179px

@media(max-width: 1024px)
  .second-header
    height: 125px
    .container
      width: 100%
      display: flex
      flex-direction: row
      justify-content: space-between
      padding: 0
      .second-header-logo
        position: unset
        display: flex
        align-items: flex-start
        padding-left: 5px
        img
          height: 55px
          margin-top: 7px
      .second-header-search
        position: absolute
        display: flex
        width: 70%
        max-height: 40px
        top: 55%
        left: 5%
        margin-top: unset
        justify-content: unset
        svg
          position: relative
          left: 2px
          top: auto
          margin-right: -25px
          margin-top: 10px
        .close-icon
          left: unset
          right: 2px
          margin-left: -25px
          margin-top: 10px
        input
          width: 70%
          font-size: 22px
          line-height: 22px
          padding-left: 40px
      .second-header-phone
        position: unset
        display: block
        width: 40px
        height: 40px
        border-radius: 50%
        background-color: #62af8d
        margin-top: 10px
        a
          color: white
          span
            display: none
          svg
            padding: 8.5px 7.5px 7.5px 7.5px
            font-size: 25px
            display: block
      .second-header-lk
        position: unset
        display: block
        width: 40px
        height: 40px
        border-radius: 50%
        background-size: cover
        margin-top: 10px
        font-size: 28px
        color: white
        background-position: right
        svg
          padding: 6.5px
          display: block
        .lk-label
          display: none
      .online-record
        position: unset
        display: block
        width: 40px
        height: 40px
        border-radius: 50%
        margin-top: 10px
        font-size: 28px
        color: #D84313
        svg
          padding: 6.5px
          display: block
        .online-record-label
          display: none
      .second-header-eye
        position: unset
        display: none
      .second-header-questions
        position: unset
        display: block
        width: 40px
        height: 40px
        border-radius: 50%
        margin-top: 10px
        font-size: 28px
        color: #D84313
        background-size: cover
        svg
          padding: 6.5px
          color: white
      .header-sound
        position: unset
        display: flex
        width: 90px
        margin-top: 7px
        margin-left: 0px
        .header-sound-icon
          position: relative
          width: 30px
        .choice-sound
          width: 50px
          left: 0
      .player-3
        position: unset
        display: none
        .rhap_main-controls
          display: none
          position: unset
    .menu-second
      height: unset
      position: relative
      a
        color: black
        display: block
        text-decoration: none
        .menu-second-element
          color: black
          display: block
          margin-top: 0
          margin-left: 0
          text-align: center
          padding: 5px 0
      a:last-of-type
        .menu-second-element:last-of-type
          display: block
          margin-left: unset
      a:first-of-type
        .menu-second-element
          display: block
          margin-left: 0
          margin-top: -10px
          text-align: center
          padding: 5px 0
      .menu-close-button
        display: block
        position: absolute
        top: -4.5vh
        right: 4vw
        font-size: 40px
      .menu-container-mobile.collapsed
        display: none
      .menu-container-mobile
        width: 100%
        font-family: "cygre_mediummedium", sans-serif
        text-transform: uppercase
        height: unset
        background: white
        position: absolute
        text-align: left
        font-size: 2vh
        top: 8px
        background: linear-gradient(0deg, #FFFFFF, rgb(255 255 255 / 93%)), linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgb(255 255 255 / 25%))
        .menu-second-element-blog
          display: block
          margin-left: unset
          height: auto
          margin-top: unset
          a
            color: black
            pointer-events: none
            text-align: center
            padding: 10px
            img
              display: none
            svg
              left: 10px
              position: relative
              top: 5px
          .sub-menu
            z-index: 1
            position: relative
            width: 100%
            left: unset
            top: -17px
            height: auto
            opacity: unset
            transform: none
            background-color: #D84313
            ul
              margin-bottom: 5px
              li
                padding: 3px 0
                a
                  padding: 0

        .menu-second-element-services
          display: block
          color: black
          margin-top: 0
          margin-left: 0
          text-align: center
          padding: 5px 0
        .menu-second-element-services:hover
          .sub-menu-services
            display: flex
            transform: unset
            width: 100%
            left: unset
            opacity: unset
            position: relative
            .services-column
              float: unset
              display: unset
              font-size: 15px
              padding-top: 0
              ul
                margin-left: 0
                padding: 0
                width: 100%
            .specs-column
              float: unset
              display: unset
              font-size: 15px
              padding-top: 0
              ul
                margin-left: 0
                padding: 0
                width: 100%
            .diseases-column
              float: unset
              display: unset
              font-size: 15px
              padding-top: 0
              ul
                margin-left: 0
                padding: 0
                width: 100%
  .service-page
    width: 100%
.admin-price
  border-top: 1px solid black
  .left-column
    width: 15%
    float: left
    min-height: 20vw
    background-color: #dbffb7
    display: inline-block
    .szh-accordion
      .razdel
        h3
          button
            width: 100%
        .szh-accordion__item-content

        .szh-accordion__item-panel
          div
            border-bottom: 1px solid black
            cursor: pointer
            padding: 2px
          div:nth-of-type(3)
            border-top: 1px solid black
          div:nth-of-type(1):hover
            background-color: #00b300
            color: #a81b1b
            border: 2px solid red
          div:hover
            background-color: green
            color: white
          .add-sub-section
            background-color: #22ff22
          .del-section
            padding: 3px
            background-color: red
            color: white
            font-weight: bold
            border: 2px solid darkred
            margin-top: 10px
          .edit-section
            padding: 3px
            background-color: #ffff46
            font-weight: bold
            width: 100%
            cursor: pointer
      .szh-accordion__item--expanded
        border: 4px solid #007974
  .right-column
    width: 84.9%
    display: inline-block
    min-height: 20vw
    background-color: #dbffb7
    border-left: 1px solid black
    .header-price-subsection
      display: flex
      justify-content: space-evenly
      .delete-price-section
        background-color: red
        color: white
        font-weight: bold
    .price-item
      border: 1px solid black
      display: table
      width: 97%
      margin: 0 auto
      .price-item-name
        width: 60%
        display: table-cell
        border-right: 1px solid black
      .price-item-price
        width: 20%
        display: table-cell
        border-right: 1px solid black
      .price-item-actions
        width: 19%
        display: table-cell

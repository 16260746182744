.admin-video-page
  .video-categories
    form
      padding-left: 50px
      div
        width: 100%
        display: flex
        label
          width: 25%
          border: 1px solid black
          input
            width: 95%
  .main-video
    form
      label
        textarea
          width: 30%
          height: 100px
.news-all-main
  .news-all-page
    width: 1340px
    margin: 50px auto 0 auto
    display: inline-block
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6784313725), rgb(255 255 255 / 90%))
    border-radius: 20px
    padding-top: 56px
    z-index: 2
    position: relative
    .bread
      margin-top: -20px
      margin-bottom: 50px
      ul
        list-style: none
        padding-left: 60px
        text-align: left
        li
          display: inline
          font-family: "cygre_bookbook", sans-serif
          font-size: 16px
          font-weight: 350
          line-height: 16px
          letter-spacing: 0em
          text-align: left
        li::after
          content: ""
          background-image: url("../../images/bread-arrow.png")
          background-repeat: no-repeat
          background-position: center
          width: 22px
          padding: 0 15px
          display: inline-block
          height: 8px
        li:last-of-type
          color: #D84313
        li:last-of-type::after
          display: none
    h1
      font-family: 'cygre_mediummedium', sans-serif
      font-size: 32px
      font-weight: 500
      line-height: 32px
      letter-spacing: 1px
      color: #D84313
      padding-left: 60px
      margin-top: 10px
      margin-bottom: 28px
    .news-items
      width: 1220px
      margin: 0 auto 50px auto
      display: flex
      flex-wrap: wrap
      .news-item
        width: 32%
        margin-right: 10px
        border: 1px solid #D9D9D9
        border-radius: 10px
        box-shadow: 0 0 4px rgba(0,0,0,0.5)
        margin-bottom: 50px
        overflow: hidden
        h2
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 22px
          font-weight: 500
          line-height: 22px
          color: #D84313
        .news-item-short
          text-align: left
          border-top: 1px solid #CFCFCF
          border-bottom: 1px solid #CFCFCF
          min-height: 180px
          p
            padding-top: 23px
            font-family: 'cygre_lightlight', sans-serif
            font-weight: 350
            font-size: 16px
            width: 90%
            margin: 0 auto
            line-height: 17.52px
        .news-date-see-more
          padding-bottom: 30px
          .news-date
            width: 44%
            display: inline-block
            text-align: left
            font-family: 'cygre_mediummedium', sans-serif
            font-weight: 500
            font-size: 16px
            padding-left: 20px
            padding-top: 30px
          .see-more
            width: 44%
            display: inline-block
            text-align: right
            font-family: 'cygre_mediummedium', sans-serif
            font-weight: 500
            font-size: 16px
            padding-right: 20px
            padding-top: 30px
        .news-item-img
          width: 100%
          img
            width: 100%
            max-height: 195px
      .news-item:nth-of-type(3n - 2)
        margin-right: 20px
      .news-item:nth-of-type(3n - 1)
        margin-right: 20px
      .news-item:nth-of-type(3n)
        margin-right: 0px


@media(max-width: 1439px)
  .news-all-main
    .news-all-page
      width: 100%
      .news-items
        width: 95%
        justify-content: space-between
        .news-item
          margin-right: unset
        .news-item:nth-of-type(3n - 2)
          margin-right: unset
        .news-item:nth-of-type(3n - 1)
          margin-right: unset


@media(max-width: 1024px)
  .news-all-main
    .news-all-page
      h1
        text-align: center
        padding-left: unset
      .news-items
        flex-direction: column
        .news-item
          width: 100%
          margin-bottom: 20px
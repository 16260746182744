.read-more-block
  width: 1220px
  margin: 0 auto 50px auto
  a
    width: 25%
    display: inline-block
    text-decoration: none
    .category-view-articles-item
      padding-right: 3%
      h4
        font-family: 'cygre_mediummedium', sans-serif
        font-size: 16px
        font-weight: 400
        min-height: 45px
        max-height: 45px
        margin-bottom: 0
        color: #D84313
      .category-view-articles-item-image
        width: 100%
        height: 275px
        background-color: #4E4E4E
        border-radius: 10px
        position: relative
        .frame
          width: 90%
          height: 90%
          overflow: hidden
          margin-left: 15px
          margin-top: 13px
          position: absolute
          border-radius: 10px
          img
            height: 275px
            margin-left: -25%
      .category-view-articles-item-text
        text-align: justify
        margin-top: 30px
        padding-right: 10px
        padding-left: 10px
        -webkit-line-clamp: 5
        display: -webkit-box
        -webkit-box-orient: vertical
        overflow: hidden
        font-family: "cygre_bookbook", sans-serif
        font-weight: 350
        font-size: 16px
        line-height: 17.6px
        min-height: 88px
        text-decoration: none
        color: #3a3a3a
    .category-view-articles-item-link
      text-align: right
      padding-right: 10px
      font-family: "cygre_bookbook", sans-serif
      font-weight: 300
      font-size: 20px
      color: #D84313
  a:last-of-type
    .category-view-articles-item
        padding-right: 0
.articles-all-main
  .main-page-contacts
    .contacts-block
      .map-side
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.6784313725), rgba(255, 255, 255, 0.9))
      .contact-side
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.6784313725), rgba(255, 255, 255, 0.9))
  .articles-all-page
    width: 1340px
    margin: 50px auto 0 auto
    display: inline-block
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6784313725), rgb(255 255 255 / 90%))
    border-radius: 20px
    padding-top: 56px
    z-index: 2
    position: relative
    .bread
      ul
        list-style: none
        padding-left: 60px
        text-align: left
        li
          display: inline
          font-family: "cygre_bookbook", sans-serif
          font-size: 16px
          font-weight: 350
          line-height: 16px
          letter-spacing: 0em
          text-align: left
        li::after
          content: ""
          background-image: url("../../images/bread-arrow.png")
          background-repeat: no-repeat
          background-position: center
          width: 22px
          padding: 0 15px
          display: inline-block
          height: 8px
        li:last-of-type
          color: #D84313
        li:last-of-type::after
          display: none
    .articles-all-page-categories
      .category
        width: 18%
        height: 44px
        border: 1px solid black
        border-radius: 10px
        margin-right: 14px
        display: inline-block
        cursor: pointer
        p
          width: fit-content
          margin: 14px auto
          vertical-align: middle
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 16px
          font-weight: 500
          a
            text-decoration: none
            color: black
      .category.active
        border: 1px solid #D84313
        color: #D84313
        p
          a
            color: #D84313
    .hided
      display: none
    .main-article
      width: 1220px
      margin: 0 auto
      h1
        font-family: 'cygre_lightlight', sans-serif
        font-weight: 400
        font-size: 32px
        color: #D84313
        padding-top: 44px
        margin-bottom: 28px
        text-align: left
      .main-article-img
        width: 750px
        height: 465px
        background-color: #4E4E4E
        border-radius: 10px
        float: left
        img
          padding: 18px
          width: 714px
          height: 429px
      .main-article-desc
        height: 465px
        width: 470px
        float: left
        position: relative
        p
          padding-left: 51px
          text-align: justify
          font-family: "cygre_bookbook", sans-serif
          font-size: 17px
          font-weight: 350
          line-height: 24px
          letter-spacing: 0.02em
          margin-top: 0
        .main-article-link
          width: fit-content
          position: absolute
          bottom: 10px
          right: 0px
          a
            font-family: "cygre_bookbook", sans-serif
            font-weight: 300
            font-size: 28px
            color: #4E4E4E
    .main-article-category-link
      width: 1220px
      margin: 50px auto 0 auto
      display: inline-block
      text-align: right
      font-family: 'cygre_lightlight', sans-serif
      font-weight: 300
      font-size: 25px
      cursor: pointer
      .arrow
        padding-left: 12px
        display: inline-block
        position: relative
        top: -4px
      a
        color: #D84313
        text-decoration: none
    .articles-all-page-categories-items
      width: 1220px
      margin: 50px auto 40px auto
      display: flex
      .articles-all-page-categories-item
        width: 25%
        display: inline-block
        a
          text-decoration: none
        h2
          font-family: 'cygre_lightlight', sans-serif
          font-weight: 700
          font-size: 25px
          color: #D84313
          width: 271px
          text-align: center
        .articles-all-page-categories-item-img
          position: relative
          .frame
            background-color: #4E4E4E
            width: 295px
            height: 277px
            border-radius: 10px
            img
              width: 275px
              border: 10px solid #4E4E4E
              border-radius: 10px
        .articles-all-page-categories-item-text
          font-family: "cygre_bookbook", sans-serif
          margin-top: 30px
          text-align: justify
          padding-left: 10px
          padding-right: 20px
          color: black
        .articles-all-page-categories-item-link
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 16px
          color: #D84313
          text-align: left
          margin-top: 15px
          padding-left: 10px
          padding-right: 20px
          height: 35px
    .main-category-view
      min-height: 1100px
      width: 1220px
      transition: 0.5s
      margin: 35px auto 0 auto
      display: flex
      position: relative
      .main-category-view-item
        width: 24%
        padding-right: 1%
        h4
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 16px
          font-weight: 500
          min-height: 45px
          max-height: 45px
          margin-bottom: 0
        .main-category-view-item-image
          width: 100%
          height: 275px
          background-color: #4E4E4E
          border-radius: 10px
          position: relative
          .frame
            width: 90%
            height: 90%
            overflow: hidden
            margin-left: 15px
            margin-top: 13px
            position: absolute
            border-radius: 10px
            img
              height: 275px
              margin-left: -25%
        .main-category-view-item-text
          text-align: justify
          margin-top: 30px
          padding-right: 10px
          padding-left: 10px
          -webkit-line-clamp: 5
          display: -webkit-box
          -webkit-box-orient: vertical
          overflow: hidden
          font-family: "cygre_bookbook", sans-serif
          font-weight: 350
          font-size: 16px
          line-height: 17.6px
          min-height: 88px
        .main-category-view-item-link
          text-align: right
          padding-right: 10px
          a
            font-family: "cygre_bookbook", sans-serif
            font-weight: 300
            font-size: 20px
            color: #D84313


@media(max-width: 1439px)
  .articles-all-main
    .articles-all-page
      width: 100%
      .articles-all-page-categories
        width: 100%
        justify-content: center
        display: flex
        .category:last-of-type
          margin-right: unset
      .main-article
        width: 95%
        margin: 0 auto
        h1
          padding: 0 40px
        .main-article-img
          width: 65%
          img
            width: -webkit-fill-available
        .main-article-desc
          width: 35%
          p
            line-height: 17px
            padding-left: 2vw
      .main-article-category-link
        width: 95%
        margin: 30px auto 0 auto
      .articles-all-page-categories-items
        width: 95%
        display: flex
        .articles-all-page-categories-item
          h2
            font-size: 1.1em
            width: auto
          .articles-all-page-categories-item-img
            .frame
              width: auto
              height: auto
              img
                width: 91%


@media(max-width: 1024px)
  .articles-all-main
    .articles-all-page
      .articles-all-page-categories
        flex-direction: column
        gap: 10px
        .category
          width: 90%
          align-self: center
          margin-right: unset
      .main-article
        h1
          text-align: center
        .main-article-img
          width: 100%
          float: unset
          height: auto
          img
            height: auto
        .main-article-desc
          width: 98%
          margin: 30px auto
          height: auto
          .main-article-link
            position: relative
            width: 100%
            text-align: right
      .main-article-category-link
        text-align: center
      .articles-all-page-categories-items
        flex-direction: column
        .articles-all-page-categories-item
          width: 100%
          a
            .articles-all-page-categories-item-link
              text-align: center
.news-item-main
  .news-item-page
    width: 1340px
    margin: 50px auto 0 auto
    display: inline-block
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6784313725), rgb(255 255 255 / 90%))
    border-radius: 20px
    padding-top: 56px
    z-index: 2
    position: relative
    .bread
      ul
        list-style: none
        padding-left: 60px
        text-align: left
        li
          display: inline
          font-family: "cygre_bookbook", sans-serif
          font-size: 16px
          font-weight: 350
          line-height: 16px
          letter-spacing: 0em
          text-align: left
        li::after
          content: ""
          background-image: url("../../images/bread-arrow.png")
          background-repeat: no-repeat
          background-position: center
          width: 22px
          padding: 0 15px
          display: inline-block
          height: 8px
        li:last-of-type
          color: #D84313
        li:last-of-type::after
          display: none
    .news-item-body
      width: 1220px
      margin: 50px auto 0 auto
      .news-item-text
        h2
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 30px
          font-weight: 500
          line-height: 30px
          color: #D84313
        h3
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 22px
          font-weight: 500
          line-height: 22px
          color: #D84313
        p
          margin-bottom: 1.5em
          font-family: "cygre_bookbook", sans-serif
          font-weight: 350
          font-size: 22px
          line-height: 22.56px
          text-align: justify
          min-height: 22px
        ul
          font-family: "cygre_bookbook", sans-serif
          font-weight: 350
          font-size: 22px
          line-height: 22.56px
          display: grid
        ol
          font-family: "cygre_bookbook", sans-serif
          font-weight: 350
          font-size: 22px
          line-height: 22.56px
          display: grid
          li
            padding: 5px 0
        img
          max-width: 70%
          border: 11px solid #4E4E4E
          border-radius: 25px
        div
          text-align: center
      .news-item-header
        display: flex
        border-bottom: 3px solid #868686
        h1
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 32px
          font-weight: 500
          line-height: 32px
          letter-spacing: 1px
          color: #D84313
          margin-top: 10px
          margin-bottom: 28px
          display: inline-block
          width: 80%
          text-align: left
        .news-item-date
          display: inline-block
          text-align: right
          font-family: 'cygre_mediummedium', sans-serif
          font-weight: 500
          font-size: 18px
          width: 20%
          padding-left: 0
          padding-top: 20px
      .news-item-image
        width: 50%
        background-color: #4E4E4E
        border-radius: 10px
        float: left
        margin-right: 20px
        margin-bottom: 20px
        margin-top: 13px
        img
          padding: 18px
          width: 94%
      h3
        font-family: 'cygre_lightlight', sans-serif
        color: #D84313
        font-size: 32px
        font-weight: 300
        line-height: 33px
        letter-spacing: 0em
        text-align: left
        margin-top: 70px
        margin-bottom: 20px
      .read-more-block
        width: 1220px
        margin: 0 auto 50px auto
        display: flex
        text-align: left
        a
          width: 25%
          display: inline-block
          text-decoration: none
          .read-more-item
            padding-right: 3%
            h4
              font-family: 'cygre_mediummedium', sans-serif
              font-size: 16px
              font-weight: 400
              min-height: 45px
              max-height: 45px
              margin-bottom: 0
              color: #D84313
            .read-more-item-image
              width: 100%
              height: 275px
              background-color: #4E4E4E
              border-radius: 10px
              position: relative
              .frame
                width: 90%
                height: 90%
                overflow: hidden
                margin-left: 15px
                margin-top: 13px
                position: absolute
                border-radius: 10px
                img
                  height: 275px
                  margin-left: -50%
            .read-more-item-text
              text-align: justify
              margin-top: 30px
              padding-right: 10px
              padding-left: 10px
              -webkit-line-clamp: 5
              display: -webkit-box
              -webkit-box-orient: vertical
              overflow: hidden
              font-family: "cygre_bookbook", sans-serif
              font-weight: 350
              font-size: 16px
              line-height: 17.6px
              min-height: 88px
              text-decoration: none
              color: #3a3a3a
            .read-more-item-link
              text-align: right
              padding-right: 10px
              font-family: "cygre_bookbook", sans-serif
              font-weight: 300
              font-size: 20px
              color: #D84313



@media(max-width: 1439px)
  .news-item-main
    .news-item-page
      width: 100%
      .news-item-body
        width: 95%
.second-header
  .container
    .search-result
      position: absolute
      width: 400px
      height: 400px
      overflow: auto
      display: block
      top: 57px
      left: 238px
      z-index: 5
      background-color: #fffffff0
      border-radius: 10px
      .search-result-block
        position: relative
        display: block
        text-align: left
        .search-result-block-item
          display: block
          position: relative
          width: 94%
          margin: 5px auto 5px auto
          min-height: 25px
          border-radius: 5px
          background-color: #ffffffc7
          box-shadow: 0 0 5px rgba(0,0,0,0.5)
          padding-top: 3px
          a
            color: black
            font-family: 'cygre_mediummedium', sans-serif
            font-size: 16px
            padding: 10px
            text-decoration: none
          a:hover
            color: #D84313
      .search-result-label
        position: relative
        text-align: left
        top: 3px
        width: 97%
        padding-left: 3%
        font-family: 'cygrebold', sans-serif
        font-weight: 700
        font-size: 15px
        color: #D84313
    ::-webkit-scrollbar
        width: 10px
    ::-webkit-scrollbar-track
      box-shadow: inset 0 0 14px 14px white
      border: solid 4px transparent
    ::-webkit-scrollbar-thumb
      box-shadow: inset 0 0 14px 14px #4E4E4E
      border: solid 4px transparent
      border-radius: 14px
    ::-webkit-scrollbar-button
      display: none


@media(max-width: 1024px)
  .second-header
    .container
      .search-result
        width: 100%
        left: 0
        top: 122px
        border-radius: 5px
.doctors
  width: 95%
  margin: 0 auto
  .doc-block-header
    width: 100%
    height: 60px
    border: 1px inset black
    margin-top: 20px
    background-color: #f0f0f0
    div
      border-right: 1px inset black
      display: inline-block
      height: 60px
    .doc-id
      width: 5%
    .doc-name
      width: 10%
    .doc-specialty
      width: 10%
    .doc-fullspecialty
      width: 10%
    .doc-about
      width: 20%
    .doc-img
      width: 10%
    .doc-howiwork
      width: 15%
    .doc-act
      width: 10%
    div:last-of-type
      border-right: none

  .doc-block
    width: 100%
    height: 100px
    border: 1px inset black
    background-color: #e8ffd6
    div
      border-right: 1px inset black
      display: inline-block
      height: 100px
      overflow: hidden
    .doc-id
      width: 5%
    .doc-name
      width: 10%
    .doc-specialty
      width: 10%
    .doc-fullspecialty
      width: 10%
    .doc-about
      width: 20%
    .doc-howiwork
      width: 15%
    .doc-img
      width: 10%
      img
        overflow: hidden
    .doc-act
      width: 10%
    div:last-of-type
      border-right: none
  .doc-block:hover
    background-color: white


.main-page-appointment
  max-width: 1340px
  margin: 45px auto 0 auto
  .appointment-block
    height: 635px
    position: relative
    background: linear-gradient(0deg, #FFFFFF, rgba(255, 255, 255, 0.7))
    border-radius: 30px
    padding-top: 65px
    .appointment-header
      width: 388px
      height: 72px
      border: 2px solid transparent
      margin: 0 auto
      background: url("../../images/headhrame2.png") no-repeat
      h2
        font-family: 'cygre_lightlight', sans-serif
        font-weight: 300
        font-size: 32px
        color: #D84313
        margin: 20px 0
    .appointment-form
      display: block
      height: 200px
      width: 1218px
      margin: 93px auto 0 auto
      .send-problem
        width: 410px
        height: 58px
        border-radius: 10px
        background-image: url("../../images/send-button.png")
        color: white
        font-family: 'cygre_mediummedium', sans-serif
        font-weight: 500
        font-size: 22px
        border: none
        margin-top: 85px
        cursor: pointer
      .send-problem:disabled
        background-image: url("../../images/send-button.png")
        opacity: 0.6
        cursor: not-allowed
      label
        width: 558px
        display: block
        float: left
        height: 69px
        margin-top: 50px
        background-color: white
        border-radius: 10px
        .appointment-label
          width: 546px
          text-align: left
          font-family: 'cygre_mediummedium', sans-serif
          font-weight: 500
          font-size: 16px
        input
          width: 515px
          height: 69px
          border: none
          background: none
          font-family: 'cygre_mediummedium', sans-serif
          font-weight: 500
          color: black
          font-size: 18px
          padding-left: 31px
        input:focus-visible
          border: none
        span
          position: relative
          bottom: 30px
          left: 37%
        .warning
          font-size: 13px
          color: #d20000
      label:nth-of-type(odd)
        margin-right: 100px
      label:last-of-type
        float: none
        width: 410px
        padding-right: 0
        padding-top: 0
        margin: 23px auto 0 auto
        padding-left: 5px
        background-color: unset
        span
          position: relative
          bottom: 30px
          left: unset
          text-align: center
          font-family: 'cygre_bookbook', sans-serif
          font-weight: 350
          font-size: 10px
          padding-right: 3px


@media(max-width: 1439px)
  .main-page-appointment
    .appointment-block
      .appointment-header

      .appointment-form
        width: 100%
        margin-top: 5vh
        label:nth-of-type(odd)
          width: 45%
          margin-right: unset
        label:last-of-type
          width: 100%
          justify-content: center
          .agree-label
            font-size: 2vh
          .agree-checkbox
            animation: blinky 2s infinite
            @keyframes blinky
              0%
                box-shadow: 0 0 10px rgb(104 216 19 / 0%)
              50%
                box-shadow: 0 0 10px rgb(104 216 19 / 100%)
              100%
                box-shadow: 0 0 10px rgb(104 216 19 / 0%)
        label
          width: 45%
          margin-top: 2vh
          input
            width: 90%
            padding-left: unset
        .input-label
          margin: 2vh 3vw
          .warning
            left: unset
            display: block
            text-align: right
            padding-right: 2vw

@media(max-width: 1024px)
  .main-page-appointment
    .appointment-block
      height: auto
      .appointment-header
        width: auto
        background-size: contain
        max-width: 410px
        h2
          font-size: 28px
      .appointment-form
        height: unset
        label
          width: 93%
        label:nth-of-type(odd)
          width: 93%
        label:last-of-type
          margin: 0 0 0 20px
          span
            bottom: unset
            .agree-label
              font-size: 12px
        .send-problem
          margin-top: 20px
          width: 100%
          max-width: 410px
.newsall
  width: 95%
  margin: 0 auto
  .news-item
    width: 100%
    height: 80px
    div
      display: inline-block
      border: 1px solid black
      overflow: auto
      height: 100%
    .news-header
      width: 10%
    .news-datetime
      width: 15%
    .news-description
      width: 25%
    .news-image
      width: 10%
    .news-short-descr
      width: 15%
    .news-date
      width: 15%
    .news-actions
      width: 10%
@font-face
  font-family: 'Cyntho Slab Pro'
  src: local('CynthoSlabPro-Regular'), url('../../fonts/Cynthoslabpro-regular.woff2') format('woff2'), url('../../fonts/Cynthoslabpro-regular.woff') format('woff')
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'Cyntho Slab Pro'
  src: local('CynthoSlabPro-Italic'), url('../../fonts/Cynthoslabpro-italic.woff2') format('woff2'), url('../../fonts/Cynthoslabpro-italic.woff') format('woff')
  font-weight: 400
  font-style: italic

.for-parrallax
  overflow: hidden
  .to-down-link
    height: 35px
    display: block
  .parallax
    height: 927px
    position: relative
    max-width: 1340px
    margin-top: 15px
    /*background-color: rgb(255 255 255 / 50%)*/
    border-radius: 20px
    z-index: 2
  img
    position: absolute
  .big-inscription
      position: absolute
      top: 50vh
      left: 28%
      pointer-events: none
      background: radial-gradient(500px 100px, #fff, #fff0)
      z-index: 2
      h1
        font-family: 'Cyntho Slab Pro', sans-serif
        font-style: normal
        font-size: 28px
        font-weight: 400
        color: #4E4E4E
        text-shadow: 0 0 10px white
      p
        width: 867px
        font-family: 'ostrovskybold', serif
        font-size: 35px
        color: #2E2E2E
        border-radius: 10px
        text-transform: uppercase
        text-shadow: 0 0 10px white
      .hippo
        font-size: 18px
  .to-down
    position: absolute
    cursor: pointer
    width: 262px
    top: 88vh
    left: 44%
    z-index: 2
    p
      width: 262px
      margin: 0
      font-family: 'cygreregular', sans-serif
      font-weight: 400
      color: white
    svg
      animation: bounce 2s
      animation-iteration-count: infinite
      @keyframes bounce
        0%, 20%, 60%, 100%
          -webkit-transform: translateY(0)
          transform: translateY(0)
        40%
          -webkit-transform: translateY(-20px)
          transform: translateY(-20px)
        80%
          -webkit-transform: translateY(-10px)
          transform: translateY(-10px)


@media(max-width: 1339px)
  .for-parrallax
    display: none
    visibility: hidden
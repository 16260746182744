.regulatory-all
  .regulatory-page
    width: 1340px
    margin: 50px auto 0 auto
    display: inline-block
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6784313725), rgb(255 255 255 / 90%))
    border-radius: 20px
    padding-top: 56px
    z-index: 2
    position: relative
    .bread
      margin-top: -20px
      margin-bottom: 50px
      ul
        list-style: none
        padding-left: 60px
        text-align: left
        li
          display: inline
          font-family: "cygre_bookbook", sans-serif
          font-size: 16px
          font-weight: 350
          line-height: 16px
          letter-spacing: 0em
          text-align: left
        li::after
          content: ""
          background-image: url("../../images/bread-arrow.png")
          background-repeat: no-repeat
          background-position: center
          width: 22px
          padding: 0 15px
          display: inline-block
          height: 8px
        li:last-of-type
          color: #D84313
        li:last-of-type::after
          display: none
    h1
      font-family: 'cygre_mediummedium', sans-serif
      font-size: 32px
      font-weight: 500
      line-height: 32px
      letter-spacing: 1px
      color: #D84313
      padding-left: 60px
      margin-top: 50px
      margin-bottom: 28px
    .intro-text
      font-family: 'cygre_lightlight', sans-serif
      font-weight: 350
      font-size: 24px
      width: 90%
      margin: 0 auto
      line-height: 25px
      text-align: left
    .regulatory-blocks
      width: 1220px
      margin: 0 auto 50px auto
      display: flex
      flex-wrap: wrap
      .regulatory-block
        margin-top: 40px
        div
          text-align: left
          font-family: 'cygre_lightlight', sans-serif
          font-weight: 350
          font-size: 24px
          line-height: 25px
          padding: 5px 0
        h2
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 24px
          font-weight: 500
          line-height: 32px
          letter-spacing: 1px
          color: #D84313
          text-align: left
        .regulatory-chief
          font-family: 'cygre_mediummedium', sans-serif
        a
          text-decoration: none
          color: #D84313
          font-weight: bold


@media(max-width: 1439px)
  .regulatory-all
    .regulatory-page
      width: 100%
      .regulatory-blocks
        width: 90%
        display: flex


@media(max-width: 1024px)
  .regulatory-all
    .regulatory-page
      h1
        padding-left: unset
        font-size: 30px
.main-page-price
  max-width: 1340px
  margin: 45px auto 0 auto
  position: relative
  .price-block
    margin: 45px auto 0 auto
    border-radius: 20px
    display: flex

    .left-side
      width: 25.523%
      border-radius: 20px
      background: rgba(255, 255, 255, 0.7)
      min-height: 860px
      display: inline-block
      padding-bottom: 30px
      .perechen-header
        margin-top: 222px
        margin-left: 44px
        width: 305px
        height: 58px
        background: url("../../images/serviceheadback1.png")
        border-radius: 10px
        position: relative
        h3
          margin: 0
          padding-top: 20px
          color: white
          font-family: 'cygre_mediummedium', sans-serif
          font-weight: 500
      .perechen
        width: 305px
        margin-left: 44px
        background-color: white
        border: 1px solid #CFCFCF
        border-radius: 10px
        padding-top: 20px
        margin-top: -20px
        .szh-accordion
          .szh-accordion__item--expanded
            h3
              button.szh-accordion__item-btn
                font-weight: 700
                color: #D84313
                svg
                  transform: rotate(180deg)
                  transition: 0.5s
                  path
                    fill: #D84313
          .razdel
            min-height: 62px
            text-align: left
            border-bottom: 1px solid #CFCFCF
            h3
              vertical-align: middle
              font-family: 'cygre_mediummedium', sans-serif
              font-weight: 500
              font-size: 16px
              color: #D84313

              .szh-accordion__item-btn
                display: flex
                height: 100%
                width: 100%
                padding: 21px 27px
                background: none
                border: none
                font-family: 'cygre_mediummedium', sans-serif
                color: black
                font-weight: 500
                font-size: 16px
                cursor: pointer
                text-align: left
                svg
                  flex-shrink: 0
                  margin-left: auto
                  transition: 0.5s
              .szh-accordion__item-btn:hover
                font-weight: 500
                color: #D84313

            .szh-accordion__item-content
              transition: height 0.3s cubic-bezier(0, 0, 0, 1)
              .szh-accordion__item-panel
                padding-bottom: 18px
                a
                  text-decoration: none
                  div
                    padding: 9px 27px
                    font-family: "cygre_bookbook", sans-serif
                    font-weight: 350
                    color: #4E4E4E
                    cursor: pointer
                  div:hover
                    color: #D84313
                  .active
                    color: #D84313
                    font-weight: 700


    .right-side
      width: 74.478%
      border-radius: 20px
      background: rgba(255, 255, 255, 0.7)
      min-height: 860px
      display: inline-block
      padding-bottom: 30px
      ::-webkit-scrollbar
        width: 10px
      ::-webkit-scrollbar-track
        box-shadow: inset 0 0 14px 14px white
        border: solid 4px transparent
      ::-webkit-scrollbar-thumb
        box-shadow: inset 0 0 14px 14px #4E4E4E
        border: solid 4px transparent
        border-radius: 14px
      ::-webkit-scrollbar-button
        display: none
      .price-header
        display: block
        width: 388px
        height: 72px
        border: 2px solid transparent
        margin: 65px auto 0 auto
        background: url("../../images/headhrame2.png") no-repeat
        h2
          font-family: 'cygre_lightlight', sans-serif
          font-weight: 300
          font-size: 32px
          color: #D84313
          margin: 20px 0
      .price-items-block
        margin-top: 80px
        overflow: auto
        margin-right: 7px

        .price-item
          width: 947px
          height: 58px
          border: 1px solid #CFCFCF
          border-radius: 10px
          display: table
          margin-left: 25px
          background-color: white
          margin-bottom: 9px
          .price-item-name
            width: 80%
            height: 100%
            display: table-cell
            vertical-align: middle
            font-family: "cygre_bookbook", sans-serif
            font-weight: 350
            font-size: 16px
          .price-item-price
            width: 20%
            height: 100%
            display: table-cell
            vertical-align: middle
            text-align: left
            font-family: 'cygrebold', sans-serif
            font-weight: 700
            color: #D84313
            font-size: 13px
            padding-left: 50px

@media(max-width: 1439px)
  .main-page-price
    width: 100%
    .price-block
      .left-side
        min-height: unset
        height: 100vh
        .perechen-header
          margin-left: unset
          width: 100%
          margin-top: 10vh
        .perechen
          width: 100%
          margin-left: unset
      .right-side
        min-height: unset
        height: 100vh
        .price-header
          margin-top: 5vh
        .price-items-block
          margin-top: 2vh
          .price-item
            width: 95%

@media(max-width: 1024px)
  .main-page-price
    width: 100%
    .price-block
      flex-direction: column
      .left-side
        width: 100%
        display: block
        height: auto
        .perechen-header
          margin-top: unset
          background-repeat: no-repeat
          background-size: cover
        .perechen
          width: 100%
          .szh-accordion
            .razdel
              min-height: unset
              h3
                .szh-accordion__item-btn
                  padding: 10px 27px
      .right-side
        width: 100%
        display: block
        height: auto
        .price-header
          display: none
        .price-items-block
          margin: 10px auto
          .price-item
            margin: 0 auto
            .price-item-price
              padding-left: unset
            .price-item-name
              padding: 0 5px
.category-view
  .category-view-page
    width: 1340px
    margin: 50px auto 0 auto
    display: inline-block
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6784313725), rgb(255 255 255 / 90%))
    border-radius: 20px
    padding-top: 20px
    z-index: 2
    position: relative
    .bread
      ul
        list-style: none
        padding-left: 60px
        text-align: left
        li
          display: inline
          font-family: "cygre_bookbook", sans-serif
          font-size: 16px
          font-weight: 350
          line-height: 16px
          letter-spacing: 0em
          text-align: left
        li::after
          content: ""
          background-image: url("../../images/bread-arrow.png")
          background-repeat: no-repeat
          background-position: center
          width: 22px
          padding: 0 15px
          display: inline-block
          height: 8px
        li:last-of-type
          color: #D84313
        li:last-of-type::after
          display: none
    .category-switch
      margin-top: 50px
      text-align: left
      padding-left: 60px
      .category
        width: 18%
        height: 44px
        border: 1px solid black
        border-radius: 10px
        margin-right: 14px
        display: inline-block
        cursor: pointer
        p
          width: fit-content
          margin: 14px auto
          vertical-align: middle
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 16px
          font-weight: 500
          a
            color: black
            text-decoration: none
      .category.active
        border: 1px solid #D84313
        color: #D84313
        p
          a
            color: #D84313

    .category-view-articles
      min-height: 1100px
      width: 1220px
      margin: 0 auto
      transition: 0.5s
      margin-top: 35px
      display: block
      position: relative
      text-align: left
      a
        width: 25%
        display: inline-block
        text-decoration: none
        margin-bottom: 40px
        .category-view-articles-item
          padding-right: 3%
          h4
            font-family: 'cygre_mediummedium', sans-serif
            font-size: 16px
            font-weight: 400
            min-height: 45px
            max-height: 45px
            margin-bottom: 0
            color: #D84313
          .category-view-articles-item-image
            width: 100%
            height: 275px
            background-color: #4E4E4E
            border-radius: 10px
            position: relative
            .frame
              width: 90%
              height: 90%
              overflow: hidden
              margin-left: 15px
              margin-top: 13px
              position: absolute
              border-radius: 10px
              img
                height: 100%
                min-width: 100%
          .category-view-articles-item-text
            text-align: justify
            margin-top: 30px
            padding-right: 10px
            padding-left: 10px
            -webkit-line-clamp: 5
            display: -webkit-box
            -webkit-box-orient: vertical
            overflow: hidden
            font-family: "cygre_bookbook", sans-serif
            font-weight: 350
            font-size: 16px
            line-height: 17.6px
            min-height: 88px
            text-decoration: none
            color: #3a3a3a
        .category-view-articles-item-link
          text-align: right
          padding-right: 10px
          font-family: "cygre_bookbook", sans-serif
          font-weight: 300
          font-size: 20px
          color: #D84313
      a:last-of-type
        .category-view-articles-item
            padding-right: 0
    h1
      font-family: 'cygre_lightlight', sans-serif
      font-weight: 700
      font-size: 32px
      color: #D84313
      padding-top: 44px
      margin-bottom: 28px


@media(max-width: 1439px)
  .category-view
    .category-view-page
      width: 100%
      .category-switch
        display: flex
        width: 100%
        justify-content: space-evenly
        padding-left: unset
        .category
          margin-right: unset
      .category-view-articles
        width: 95%

@media(max-width: 1024px)
  .category-view
    .category-view-page
      .category-switch
        flex-direction: column
        gap: 10px
        .category
          width: 90%
          align-self: center
          margin-right: unset
      .category-view-articles
        a
          width: 100%
          .category-view-articles-item
            padding-right: unset
            width: 99%
            h4
              text-align: center
              font-size: 18px
            .category-view-articles-item-image
              .frame
                width: 93%
                img
                  margin-left: unset
.blog-main
  text-align: left
  display: flex
  .left-column
    width: 20%
    display: inline-block
    background-color: #ff5e5e
    min-height: 50vw
  .right-column
    width: 80%
    display: inline-block
    background-color: cadetblue
    min-height: 50vw
.main-page-contacts
  max-width: 1340px
  margin: 45px auto 0 auto
  .contacts-block
    height: 757px
    position: relative
    .map-side
      height: 757px
      width: 56%
      background-color: rgba(255, 255, 255, 0.5)
      border-radius: 30px
      float: left
      .map-main
        padding-left: 89px
        padding-top: 88px
      div
        .map-block
          margin-left: 89px
          margin-top: 88px
      h3
        font-family: 'cygre_bookbook', sans-serif
        font-weight: 350
        font-size: 18px
        line-height: 19.71px
        color: black
        margin-bottom: 37px
        margin-top: 34px
      .social
        width: 67%
        margin: 0 auto
        display: flex
        .social-item
          width: 33%
    .contact-side
      height: 757px
      width: 44%
      background-color: rgba(255, 255, 255, 0.5)
      border-radius: 30px
      float: left
      h2
        font-family: 'cygre_lightlight', sans-serif
        font-weight: 300
        font-size: 32px
        color: #D84313
        margin-top: 88px
        margin-left: 83px
        text-align: left
        width: 41%
        border-bottom: 1px solid white
        padding-bottom: 9px
      .tel
        margin-left: 83px
        text-align: left
        margin-top: 56px
        h3
          font-family: 'cygre_lightlight', sans-serif
          font-weight: 300
          font-size: 32px
          color: #D84313
          margin-bottom: 12px
        a
          font-family: 'cygre_bookbook', sans-serif
          font-weight: 350
          font-size: 18px
          line-height: 18.45px
          color: black
          text-decoration: none
      .adr
        margin-left: 83px
        text-align: left
        margin-top: 47px
        h3
          font-family: 'cygre_lightlight', sans-serif
          font-weight: 300
          font-size: 32px
          color: #D84313
          margin-bottom: 0
        p
          font-family: 'cygre_bookbook', sans-serif
          font-weight: 350
          font-size: 18px
          line-height: 18.45px
          margin-top: 12px
          color: black
      .chas
        margin-left: 83px
        text-align: left
        margin-top: 47px
        h3
          font-family: 'cygre_lightlight', sans-serif
          font-weight: 300
          font-size: 32px
          color: #D84313
          margin-bottom: 0
        p
          font-family: 'cygre_bookbook', sans-serif
          font-weight: 350
          font-size: 18px
          line-height: 18.45px
          margin-top: 12px
          color: black
      .mail
        margin-left: 83px
        text-align: left
        margin-top: 47px
        h3
          font-family: 'cygre_lightlight', sans-serif
          font-weight: 300
          font-size: 32px
          color: #D84313
          margin-bottom: 12px
        a
          font-family: 'cygre_bookbook', sans-serif
          font-weight: 350
          font-size: 18px
          line-height: 18.45px
          color: black
          text-decoration: none
      .button-priem
        margin-top: 77px
        button
          width: 410px
          height: 58px
          background-image: url("../../images/send-button.png")
          color: white
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 18px
          line-height: 18.45px
          border: none
          border-radius: 10px
          cursor: pointer

@media(max-width: 1439px)
  .main-page-contacts
    .contacts-block
      .map-side
        .map-main
          padding-left: 3vw
          div
            max-width: 90vw
      .contact-side
        h2
          margin-left: 2vw
          width: 90%
        .tel
          margin-left: 2vw
        .adr
          margin-left: 2vw
        .chas
          margin-left: 2vw
        .mail
          margin-left: 2vw
        .button-priem
          margin-top: 7vh


@media(max-width: 1024px)
  .main-page-contacts
    .contacts-block
      height: auto
      display: flex
      flex-direction: column-reverse
      .map-side
        height: auto
        width: 100%
        float: unset
        .map-main
          width: 90vw
          margin: 0 auto
          padding-left: unset
        .social
          padding-bottom: 20px
      .contact-side
        float: unset
        height: auto
        width: 100%
        h2
          margin: 50px auto 0 auto
        .tel
          margin: 50px auto 0 auto
          width: 90%
        .adr
          margin: 50px auto 0 auto
          width: 90%
        .chas
          margin: 50px auto 0 auto
          width: 90%
          p
            font-size: 16px
        .mail
          margin: 50px auto 0 auto
          width: 90%
        .online-record
          .button-priem
            button
              width: 100%
              max-width: 410px
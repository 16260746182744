.control-panel
  min-height: 60vh
  display: flex
  background-color: #67B32C
  .admin-menu
    width: 15%
    float: left
    display: flex
    border-right: 2px solid white
    ul
      width: 100%
      list-style: none
      padding-left: 0
      margin-top: 0
      li
        padding: 10px 0
        background-color: #2C5810
        color: white
        border: 1px solid white
        cursor: pointer
      li:hover
        background-color: #67B32C

  .admin-content
    width: 85%
    float: left
.video-main
  .video-main-page
    width: 1340px
    margin: 50px auto 0 auto
    display: inline-block
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6784313725), rgb(255 255 255 / 90%))
    border-radius: 20px
    z-index: 2
    position: relative
    .bread
      margin-top: 40px
      ul
        list-style: none
        padding-left: 60px
        text-align: left
        li
          display: inline
          font-family: "cygre_bookbook", sans-serif
          font-size: 16px
          font-weight: 350
          line-height: 16px
          letter-spacing: 0em
          text-align: left
          a
            text-decoration: none
            color: black
        li::after
          content: ""
          background-image: url("../../images/bread-arrow.png")
          background-repeat: no-repeat
          background-position: center
          width: 22px
          padding: 0 15px
          display: inline-block
          height: 8px
        li:last-of-type
          color: #D84313
        li:last-of-type::after
          display: none
    .video-categories
      width: 1220px
      margin: 50px auto 0 auto
      text-align: left
      .video-category
        width: 18%
        height: 44px
        border: 1px solid black
        border-radius: 10px
        margin-right: 14px
        display: inline-block
        cursor: pointer
        .video-category-name
          width: fit-content
          margin: 14px auto
          vertical-align: middle
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 16px
          font-weight: 500
          a
            text-decoration: none
            color: black
      .video-category-active
        width: 18%
        height: 44px
        border: 1px solid #D84313
        border-radius: 10px
        margin-right: 14px
        display: inline-block
        cursor: pointer
        color: #D84313
        .video-category-name
          width: fit-content
          margin: 14px auto
          vertical-align: middle
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 16px
          font-weight: 500
          a
            text-decoration: none
            color: #D84313
    .main-video
      width: 1220px
      margin: 63px auto 0 auto
      display: flex
      .player
        display: block
        margin-right: 32px
        border: 15px solid #4E4E4E
        border-radius: 10px
        float: left
      .video-text-block
        width: 42%
        height: 392px
        float: left
        .main-video-header
          font-family: 'cygre_lightlight', sans-serif
          font-weight: 400
          font-size: 32px
          color: #D84313
          text-align: left
        .main-video-text
          font-family: "cygre_bookbook", sans-serif
          font-size: 16px
          font-weight: 350
          line-height: 18px
          letter-spacing: 0
          text-align: left
          margin-top: 30px
          color: black
    .video-categories-block
      margin-top: 73px
      margin-bottom: 50px
      .video-category-link
        width: 1220px
        margin: 50px auto 0 auto
        text-align: right
        font-family: 'cygre_lightlight', sans-serif
        font-weight: 300
        font-size: 25px
        cursor: pointer
        a
          color: #D84313
          text-decoration: none
        .arrow
          padding-left: 12px
          display: inline-block
          position: relative
          top: -4px
      .video-playlists
        width: 1220px
        margin: 50px auto 0 auto
        display: flex
        a
          width: 34%
          text-decoration: none
          .video-playlists-item
            div
              width: 365px
            .video-playlists-item-header
              h3
                font-family: 'cygre_lightlight', sans-serif
                font-weight: 700
                font-size: 25px
                color: #D84313
                text-align: center
            .video-playlists-item-img
              height: 350px
              background-color: #4E4E4E
              border-radius: 10px
              img
                padding-top: 15px
            .video-playlists-item-text
              padding-right: 40px
              text-align: left
              font-family: "cygre_bookbook", sans-serif
              font-size: 16px
              font-weight: 350
              color: black
              line-height: 18px
              margin-top: 36px
            .video-playlists-item-link
              font-family: 'cygre_mediummedium', sans-serif
              font-size: 16px
              font-weight: 500
              color: #D84313
              margin-top: 20px
              text-align: left
        a:last-of-type
          .video-playlists-item
            div
              margin-left: 40px
        a:nth-of-type(2)
          .video-playlists-item
            div
              margin-left: 25px


@media(max-width: 1439px)
  .video-main
    .video-main-page
      width: 100%
      .video-categories
        width: 100%
        display: flex
        justify-content: center
      .main-video
        width: 95%
        margin: 50px auto 0 auto
      .video-categories-block
        margin-top: 40px
        .video-category-link
          width: 95%
          margin: 0 auto 40px auto
        .video-playlists
          width: 95%
          margin: 0 auto
          gap: 20px
          a
            width: 32%
            .video-playlists-item
              .video-playlists-item-header
                width: 100%
              .video-playlists-item-img
                width: auto
                height: auto
                img
                  height: auto
                  width: 90%
                  padding: 15px
              .video-playlists-item-text
                width: 100%
                padding: unset
              .video-playlists-item-link
                width: 100%
          a:nth-of-type(2)
            .video-playlists-item
              div
                margin-left: unset
          a:last-of-type
            .video-playlists-item
              div
                margin-left: unset

@media(max-width: 1024px)
  .video-main
    .video-main-page
      .video-categories
        flex-direction: column
        gap: 10px
        .video-category
          width: 90%
          align-self: center
      .main-video
        flex-direction: column
        .player
          width: 95% !important
          border: 7px solid #4E4E4E
        .video-text-block
          height: auto
          width: 98%
          .main-video-header
            text-align: center
            margin-top: 20px
      .video-categories-block
        .video-category-link
          margin: 20px auto
        .video-playlists
          flex-direction: column
          a
            width: 100%
            .video-playlists-item
              .video-playlists-item-link
                text-align: center
.services
  .service-page
    width: 1340px
    margin: 50px auto 0 auto
    display: inline-block
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6784313725), rgb(255 255 255 / 90%))
    border-radius: 20px
    padding-top: 56px
    z-index: 2
    position: relative
    .bread
      ul
        list-style: none
        padding-left: 60px
        text-align: left
        li
          display: inline
          font-family: "cygre_bookbook", sans-serif
          font-size: 16px
          font-weight: 350
          line-height: 16px
          letter-spacing: 0em
          text-align: left
        li::after
          content: ""
          background-image: url("../../images/bread-arrow.png")
          background-repeat: no-repeat
          background-position: center
          width: 22px
          padding: 0 15px
          display: inline-block
          height: 8px
        li:last-of-type
          color: #D84313
        li:last-of-type::after
          display: none
    .service-page-text
      width: 1220px
      margin: 0 auto
      text-align: left
      .service-page-text-image
        width: 100%
        img
          width: 80%
      h1
        font-family: 'cygre_mediummedium', sans-serif
        font-size: 32px
        font-weight: 500
        line-height: 32px
        letter-spacing: 1px
        text-align: left
        color: #D84313
        margin-top: 59px
        margin-bottom: 50px
      p
        margin-bottom: 1.5em
        font-family: "cygre_bookbook", sans-serif
        font-weight: 350
        font-size: 22px
        line-height: 22.56px
        text-align: justify
      .ceni
        font-family: 'cygre_mediummedium', sans-serif
        font-size: 22px
        font-weight: 500
        text-align: left
      .usluga
        font-family: 'cygre_mediummedium', sans-serif
        font-size: 18px
        font-weight: 500
        text-align: left
      .cena
        font-family: 'cygre_mediummedium', sans-serif
        font-size: 18px
        font-weight: 500
        text-align: left
        color: #D84313
      img
        display: block
        border-radius: 10px
        border: 18px solid #4E4E4E
        margin: 0 auto
        max-width: 90%
      h2
        font-family: 'cygre_mediummedium', sans-serif
        font-size: 30px
        font-weight: 500
        line-height: 22px
        color: #D84313
      h3
        font-family: 'cygre_mediummedium', sans-serif
        font-size: 22px
        font-weight: 500
        line-height: 22px
        color: #D84313
      p
        margin-bottom: 1.5em
        font-family: "cygre_bookbook", sans-serif
        font-weight: 350
        font-size: 22px
        line-height: 22.56px
        text-align: justify
        min-height: 22px
      ul
        font-family: "cygre_bookbook", sans-serif
        font-weight: 350
        font-size: 22px
        line-height: 22.56px
        display: grid
      ol
        font-family: "cygre_bookbook", sans-serif
        font-weight: 350
        font-size: 22px
        line-height: 22.56px
        display: grid
        li
          padding: 5px 0


@media(max-width: 1439px) and (orientation: landscape)
  .services
    .service-page
      width: 100%
      .service-page-text
        width: 95%


@media(max-width: 1024px)
  .services
    .service-page
      width: 100%
      .service-page-text
        width: 95%
        img
          max-width: 85vw
.video-main
  .video-page
    width: 1340px
    margin: 50px auto 0 auto
    display: inline-block
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6784313725), rgb(255 255 255 / 90%))
    border-radius: 20px
    z-index: 2
    position: relative
    .bread
      margin-top: 40px
      ul
        list-style: none
        padding-left: 60px
        text-align: left
        li
          display: inline
          font-family: "cygre_bookbook", sans-serif
          font-size: 16px
          font-weight: 350
          line-height: 16px
          letter-spacing: 0em
          text-align: left
          a
            text-decoration: none
            color: black
        li::after
          content: ""
          background-image: url("../../images/bread-arrow.png")
          background-repeat: no-repeat
          background-position: center
          width: 22px
          padding: 0 15px
          display: inline-block
          height: 8px
        li:last-of-type
          color: #D84313
        li:last-of-type::after
          display: none
    .video-categories
      width: 1220px
      margin: 50px auto 0 auto
      text-align: left
      .video-category
        width: 18%
        height: 44px
        border: 1px solid black
        border-radius: 10px
        margin-right: 14px
        display: inline-block
        cursor: pointer
        .video-category-name
          width: fit-content
          margin: 14px auto
          vertical-align: middle
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 16px
          font-weight: 500
          a
            text-decoration: none
            color: black
      .video-category-active
        width: 18%
        height: 44px
        border: 1px solid #D84313
        border-radius: 10px
        margin-right: 14px
        display: inline-block
        cursor: pointer
        color: #D84313
        .video-category-name
          width: fit-content
          margin: 14px auto
          vertical-align: middle
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 16px
          font-weight: 500
          a
            text-decoration: none
            color: #D84313
    h1
      font-family: 'cygre_mediummedium', sans-serif
      font-size: 32px
      font-weight: 500
      color: #D84313
      margin-top: 40px
    .YT-playlist
      width: 1220px
      margin: 50px auto 0 auto


@media(max-width: 1439px)
  .video-main
    .video-page
      width: 100%
      .video-categories
        width: 100%
        display: flex
        justify-content: center
      .YT-playlist
        width: 95%

@media(max-width: 1024px)
  .video-main
    .video-page
      .video-categories
        flex-direction: column
        gap: 10px
        .video-category
          width: 90%
          align-self: center
        .video-category-active
          width: 90%
          align-self: center
.specs-main
  .specs-page
    width: 1340px
    margin: 50px auto 0 auto
    display: inline-block
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6784313725), rgb(255 255 255 / 90%))
    border-radius: 20px
    padding-top: 56px
    z-index: 2
    position: relative
    min-height: 80vh
    .bread
      margin-top: -20px
      margin-bottom: 50px
      ul
        list-style: none
        padding-left: 60px
        text-align: left
        li
          display: inline
          font-family: "cygre_bookbook", sans-serif
          font-size: 16px
          font-weight: 350
          line-height: 16px
          letter-spacing: 0em
          text-align: left
        li::after
          content: ""
          background-image: url("../../images/bread-arrow.png")
          background-repeat: no-repeat
          background-position: center
          width: 22px
          padding: 0 15px
          display: inline-block
          height: 8px
        li:last-of-type
          color: #D84313
        li:last-of-type::after
          display: none
    .doctors-filter
      width: 1220px
      margin: 0 auto
      text-align: left
      .prof-button
        width: fit-content
        height: 44px
        border: 1px solid black
        border-radius: 10px
        margin-right: 14px
        display: inline-block
        cursor: pointer
        margin-bottom: 10px
        .prof-button-text
          padding: 10px 20px
      .prof-button-active
        width: fit-content
        height: 44px
        border: 1px solid #D84313
        border-radius: 10px
        margin-right: 14px
        display: inline-block
        cursor: pointer
        margin-bottom: 10px
        .prof-button-text
          padding: 10px 20px
          color: #D84313
          font-weight: 700

    .search-results
      width: 1220px
      margin: 0 auto
      text-align: left
      padding-bottom: 50px
      .spec-block
        width: 360px
        height: 345px
        display: inline-block
        background-color: white
        margin-right: 35px
        margin-top: 48px
        border-radius: 10px
        a
          color: unset
          text-decoration: none
        .spec-img
          padding-top: 15px
          img
            margin-left: 15px
            max-height: 219px
            max-width: 330px
        .spec-fio
          text-align: left
          padding-left: 15px
          width: 65%
          display: inline-block
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 16px
          text-transform: uppercase
          h4
            margin-bottom: 10px
          a
            color: black
            text-decoration: none
        .spec-stag
          width: 30%
          display: inline-block
          text-align: left
          font-family: 'cygreregular', sans-serif
          font-size: 13px
          span
            color: #D84313
        .spec-spec
          padding-left: 15px
          text-align: left
          font-family: 'cygre_bookbook', sans-serif
          font-size: 16px


@media(max-width: 1024px)
  .specs-main
    .specs-page
      width: 100%
      .doctors-filter
        width: 95%
        margin: 0 auto
        .prof-button
          height: auto
          margin-right: 5px
          margin-bottom: 5px
          .prof-button-text
            padding: 5px 10px
        .prof-button-active
          height: auto
          margin-right: 5px
          margin-bottom: 5px
          .prof-button-text
            padding: 5px 10px
      .search-results
        width: 95%
        margin: 0 auto
        display: flex
        flex-direction: column
        .spec-block
          margin: 10px auto
          width: 100%
          max-width: 400px
          .spec-img
            width: 100%
            img
              width: 91%
              max-width: unset
              max-height: unset
              margin: 0 auto
              display: block

.spec
  max-width: 1340px
  margin: 45px auto 0 auto
  position: relative
  .main-spec-block
    margin: 45px auto 0 auto
    padding: 65px 32px 30px 32px
    border-radius: 20px
    background: rgb(255 255 255 / 70%)


    .spec-header
      width: 388px
      height: 72px
      border: 2px solid transparent
      margin: 0 auto
      background: url("../../images/headhrame2.png") no-repeat
      position: relative
      h2
        font-family: 'cygre_lightlight', sans-serif
        font-weight: 300
        font-size: 32px
        color: #D84313
        margin: 20px 0
    .spec-list
      .spec-block
        width: 360px
        height: 345px
        display: inline-block
        background-color: white
        margin-right: 35px
        margin-top: 48px
        border-radius: 10px
        a
          color: black
          text-decoration: none
        .spec-img
          padding-top: 15px
          img
            max-height: 219px
            max-width: 330px
        .spec-fio
          text-align: left
          padding-left: 15px
          width: 65%
          display: inline-block
          font-family: 'cygre_mediummedium', sans-serif
          font-size: 16px
          text-transform: uppercase
          h4
            margin-bottom: 10px
        .spec-stag
          width: 30%
          display: inline-block
          text-align: left
          font-family: 'cygreregular', sans-serif
          font-size: 13px
          span
            color: #D84313
        .spec-spec
          padding-left: 15px
          text-align: left
          font-family: 'cygre_bookbook', sans-serif
          font-size: 16px
    .more-specs-link
      position: relative
      bottom: 0px
      right: 47px
      font-family: "cygre_bookbook", sans-serif
      font-weight: 300
      font-size: 32px
      color: #D84313
      text-align: right
      margin-top: 40px
      margin-bottom: 10px
      a
        text-decoration: none
        color: #D84313
        svg
          padding-left: 20px
          vertical-align: middle


@media(max-width: 1024px)
  .spec
    .main-spec-block
      padding: 50px 20px
      .spec-header
        width: auto
        background-size: contain
        max-width: 410px
        h2
          font-size: 28px
      .spec-list
        .spec-block
          width: 100%
          margin-top: 30px
          margin-right: unset
          .spec-img
            img
              width: 91%
              max-width: unset
              max-height: unset
      .more-specs-link
        right: unset

@media(max-width: 1024px) and (orientation: landscape)
  .spec
    .main-spec-block
      .spec-list
        .spec-block
          max-width: 410px
          min-height: 360px

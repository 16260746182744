@keyframes slideIn
  0%
    opacity: 0
  100%
    opacity: 1

.timeline
  .timeline-eventline
    height: 1px
    width: 717px
    transform-origin: left center
    background-color: #4E4E4E
    display: block
    margin: 90px auto 0 auto
  .events-bar
    list-style: none
    margin-top: 0
    width: 717px
    li
      display: inline-block
      position: relative
      cursor: pointer
      .dot
        width: 13px
        height: 13px
        display: block
        position: relative
        top: -11px
        border-radius: 50%
        background-color: #4E4E4E
        transition: 0.1s
      .year
        top: -40px
        position: absolute
        left: -7px
        font-family: "cygre_bookbook", sans-serif
        font-size: 13px
        color: #4E4E4E
        transition: 0.2s
    li:last-of-type
      position: relative
      .year
        left: 24px
      .dot
        left: 85%
    li.active
      .year
        font-size: 32px
        color: #D84313
        top: -60px
        left: -28px
        transition: 0.2s
        background-image: radial-gradient(circle farthest-corner at 50% 50%, #fff, #ffffffc4)
        box-shadow: 0 0 13px white
        z-index: 1
      .dot
        background-color: #D84313
    li:last-of-type.active
      position: relative
      .year
        left: 3px
      .dot
        left: 85%
    li:hover
      .dot
        width: 16px
        height: 16px
        transition: 0.1s
        top: -10px
  .year-detail
    width: 717px
    margin: 26px auto 0 auto
    display: flex
    .year-text
      width: 50%
      font-family: "cygre_bookbook", sans-serif
      font-size: 16px
      p
        width: 325px
        margin-top: 0
    .year-img
      width: 50%
      img
        border-radius: 10px
        display: block
        margin: 0 auto
        width: 100%


@media(max-width: 1024px)
